import React from 'react'
import {Card, Col, Container, Row} from 'react-bootstrap'

const AboutUs: React.FC = () => {
  return (
    <Container className='mt-5'>
      <h2 className='text-white'>About Us</h2>
      <Row className='g-4'>
        <Col md={6}>
          <Card className='p-20'>
            <Card.Body>
              <Card.Title className='h4'>Our Company</Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis urna vel libero
                interdum vulputate. Curabitur tristique, arcu in euismod vehicula, arcu libero
                tempor ante, eget tincidunt odio quam id orci.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className='p-20'>
            <Card.Body>
              <Card.Title className='h4'>Our Team</Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis urna vel libero
                interdum vulputate. Curabitur tristique, arcu in euismod vehicula, arcu libero
                tempor ante, eget tincidunt odio quam id orci.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutUs
