import firebase from '../../../config/firebase'
import axios from 'axios'
import {updateUser} from '../../apps/user-management/users-list/core/_requests'
import {getAuth, setAuth} from '../../auth'
import {RepositoryFactory} from '../../../repository/RepositoryFactory'
// const BASE_URL = 'https://rose-glamorous-moose.cyclic.app'
const BASE_URL = 'https://us-central1-greetrz.cloudfunctions.net/app'
let BillingApi = RepositoryFactory.get('billing')
let UserApi = RepositoryFactory.get('user')

// ============Create Billing Card==============
export async function createBillingCard(
  data: any,
  onSuccess = () => {},
  onError = (res: any) => {}
) {
  try {
    const response = await BillingApi.createBilling(data)
    console.log('response', response)
    onSuccess()
    return response
  } catch (err: any) {
    // alert(err.message)
    onError(err?.response?.data?.error)
  }
}
export async function fetchBillingCards(id: any, onSuccess = () => {}) {
  try {
    const response = await axios.get(`${BASE_URL}/payment/get-cards/${id}`)
    onSuccess()
    return response.data.data
  } catch (err: any) {
    console.log(err)
  }
}
export async function createBillingAddress(
  billingAddressObj: any,
  onSuccess = () => {},
  onError = (error: any) => {}
) {
  let {id, ...rest} = billingAddressObj
  try {
    firebase
      .firestore()
      .collection('billingAddresses')
      .add({...rest})
      .then((res) => {
        onSuccess()
      })
  } catch (err: any) {
    // alert(err.message)
    onError(err?.message)
  }
}

// export function listenToBillingError(id:any, onUpdate = (data:any) => {}, onError = (error:any) => {}) {
//   const userDocRef = firebase.firestore().collection('users').doc(id)

//   try {
//     const unsubscribe = userDocRef.onSnapshot(
//       (snapshot) => {
//         if (snapshot.exists) {
//           const userData = snapshot.data()
//           onUpdate(userData)
//         } else {
//           onUpdate(null)
//         }
//       },
//       (error) => {
//         console.error('Error listening to user data:', error)
//         onError(error)
//       }
//     )

//     return unsubscribe
//   } catch (error) {
//     console.error('Error occurred while setting up the listener:', error)
//     onError(error)
//     return error
//   }
// }

export async function updateBillingAddress(
  billingAddressObj: any,
  onSuccess = () => {},
  onError = (err: any) => {}
) {
  let {id, ...rest} = billingAddressObj
  try {
    await firebase
      .firestore()
      .collection('billingAddresses')
      .doc(id)
      .update({...rest})
      .then((res) => {
        onSuccess()
      })
  } catch (err: any) {
    // alert(err.message)
    onError(err.message)
  }
}
// ! Card Payment Functions
export async function addCardPayment(cardPaymentObj: any) {
  let {name, email, cardNo, expiryDate, cvc, customer_id} = cardPaymentObj
  let payload = null
  let endPoint = null
  if (customer_id) {
    // Add a Card
    payload = {
      name,
      cardNo,
      expiryDate,
      cvc,
      customer_id,
    }
    endPoint = '/create-card'
  } else {
    // Create Customer / Card
    payload = {
      name,
      email,
      cardNo,
      expiryDate,
      cvc,
    }
    endPoint = '/create-customer'
  }
  let {data} = await axios.post(`${BASE_URL}/api/v1/payment/${endPoint}`, payload)
  if (data.success) {
    if (!customer_id) {
      let {
        customer: {id: stripe_customer_id},
      } = data
      let auth: any = getAuth()
      await updateUser({stripe_customer_id, id: auth?.uid})
      auth.stripe_customer_id = stripe_customer_id
      setAuth(auth)
    }
    // Fetch Customer
  }
}
export async function removeCardPayment(data: any) {
  let response = await axios.delete(`${BASE_URL}/payment/delete-card`, {data})
  console.log(response)
  // alert(response?.message)
}
export async function updatePaymentCard(data: any, callback: any) {
  console.log(data)
  let response = await axios.put(`${BASE_URL}/payment/update-card`, data)
  console.log(response)
  callback(response)
  // alert(response?.message)
}
export async function fetchCustomer(cardPaymentObj: any, callback: any) {
  let {customer_id} = cardPaymentObj
  console.log('customer_id:', customer_id)
  let {data} = await axios.get(`${BASE_URL}/api/v1/payment/customer-details/${customer_id}`)
  if (data.success) {
    let {
      paymentMethod: {data: paymentMethods},
    } = data
    callback(paymentMethods)
  }
}

// ============PLANS SECTION==============
// ============Fetch all plans==============
export async function fetchAllPlans(currency: any ,onSuccess = (res: any) => {}, onError = (err: any) => {}) {
  try {
    console.log(currency,'ahsancurrency')
    const response = await axios.get(`${BASE_URL}/payment/get-packages${currency}`);


    const fetchedData = await Promise.all(
      response.data.data.map(async (element: {id: string | undefined}) => {
        const result = await firebase.firestore().collection('pricings').doc(element.id).get()
        const additionalData = result.data() || {} // Handle the case when result.data() is undefined
        return {...element, ...additionalData}
      })
    )
    onSuccess(fetchedData)
    // return fetchedData
  } catch (err) {
    console.error('Error fetching plans:', err)
    onError('An error occurred while fetching plans')
    // alert('An error occurred while fetching plans.')
  }
}


// ============Fetch Single plan==============
export async function fetchSinglePlan(id: any, onSuccess = () => {}) {
  try {
    const response = await axios.get(`${BASE_URL}/payment/get-cards/${id}`)
    onSuccess()
    return response.data.data
  } catch (err: any) {
    alert(err.message)
  }
}
// ============Subscribe plan==============
export async function subscribePlan(data: any, onSuccess = () => {}, onError = (err: any) => {}) {
  try {
    const response = await axios.post(`${BASE_URL}/payment/subscribe`, data)
    onSuccess()
    return response
  } catch (err: any) {
    onError(err?.response?.data?.error)
    // alert(err.message)
  }
}
// ============Unsubscribe plan==============
export async function unsubscribePlan(id: any, onSuccess = () => {}, onError = (err: any) => {}) {
  try {
    const response = await axios.get(`${BASE_URL}/payment/un-subscribe/${id}`)
    onSuccess()
    return response
  } catch (err: any) {
    onError(err?.response?.data?.error)
    // alert(err.message)
  }
}
// ============My Subscription==============
export async function getMySubscription(id: any, onSuccess = () => {}, onError = () => {}) {
  try {
    const response = await axios.get(`${BASE_URL}/payment/my-subscription/${id}`)
    onSuccess()
    return response.data
  } catch (err: any) {
    onError()
    console.log(err)
  }
}
export async function addCoupon(data: any, onSuccess = () => {}, onError = () => {}) {
  try {
    const response = await BillingApi.addCoupon(data)
    onSuccess()
    return response.data
  } catch (err: any) {
    onError()
    console.log(err)
    return err
  }
}

export async function fetchInvoices(id: any, onSuccess = (data: any) => {}) {
  try {
    firebase
      .firestore()
      .collection('invoices')
      .where('userId', '==', id)
      .onSnapshot((snapshot) => {
        const invoices = snapshot.docs.map((doc) => doc.data())
        onSuccess(invoices)
        console.log(invoices)
      })
  } catch (err: any) {
    console.log(err)
  }
}

export async function verifyDomain(
  payload: any,
  onSuccess = (data: any) => {},
  onError = (err: any) => {}
) {
  try {
    console.log(payload, 'payloadhamza')

    let {data} = await UserApi.verifyDomain(payload)
    if (data.success) {
      onSuccess(data.message)
      console.log('onSuccess successfully')
    } else {
      onError('Unable to verify your domain')
      onError(data?.message || data?.error || 'Unable to verify')
    }
  } catch (err: any) {
    onError(err?.response?.data?.message || err?.response?.data?.error || 'Unable to verify')
  }
}

export async function getSmtpSettings(id: any, onSuccess = (data: any) => {}) {
  try {
    firebase
      .firestore()
      .collection('smtpSettings')
      .where('userId', '==', id)
      .onSnapshot(async (snapshot) => {
        const smtp = snapshot.docs.map(async (doc) => {
          const smtpData = doc.data();
          const serviceId = smtpData.serviceId;
          const serviceProviderSnapshot = await firebase.firestore().collection('serviceProviders').doc(serviceId).get();
          const serviceProviderData = serviceProviderSnapshot.data();
          return { ...smtpData, serviceProvider: serviceProviderData };
        });
        const resolvedSmtp = await Promise.all(smtp);
        console.log(resolvedSmtp,'resolvedSmtp')
        onSuccess(resolvedSmtp[0]);
        console.log(resolvedSmtp);
      });
  } catch (err: any) {
    console.log(err);
  }
}

export async function updateDomain(
  payload: any,
  onSuccess = (data: any) => {},
  onError = (err: any) => {}
) {
  try {
    console.log(payload, 'ahsanpaylaod')
    let {data} = await UserApi.updateDomain(payload)
    console.log(data, 'ahsanupdated')
    if (data.success) {
      onSuccess(data.message)

      console.log('onsucess sucessfully')
    } else {
      onError('Unable to verify your domain')
      onError(data?.message || data?.error || 'Unable to verify')
    }
  } catch (err: any) {
    onError(err?.response?.data?.message || err?.response?.data?.error || 'Unable to verify')
  }
}
export async function updateUserAdminDomain(
  payload: any,
  onSuccess = (data: any) => {},
  onError = (err: any) => {}
) {
  try {
    console.log(payload, 'ahsanpaylaod')
    let {data} = await UserApi.updateUserDomain(payload)
    console.log(data, 'ahsanupdated')
    if (data.success) {
      onSuccess(data.message)

      console.log('onsucess sucessfully')
    } else {
      onError('Unable to verify your domain')
      onError(data?.message || data?.error || 'Unable to verify')
    }
  } catch (err: any) {
    onError(err?.response?.data?.message || err?.response?.data?.error || 'Unable to verify')
  }
}

export async function getSmtpService(onSuccess = (data: any) => {}) {
  try {
    firebase
      .firestore()
      .collection('serviceProviders')
      .onSnapshot((snapshot) => {
        const smtp = snapshot.docs.map((doc) => ({serviceId: doc.id, ...doc.data()}))
        onSuccess(smtp)
        console.log(smtp)
      })
  } catch (err: any) {
    console.log(err)
  }
}
