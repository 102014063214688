import {useEffect, useState} from 'react'
import Loader from '../../../accounts/components/Loading/Loader'
import {getNotifications, readNotification} from './core/_requests'
import {useAuth} from '../../../auth'
import {useNavigate} from 'react-router-dom'
import BreadCrum from '../../global/BreadCrum'
import { formatCreatedAt } from '../../../../../_metronic/helpers/FormatTime'
import moment from 'moment'

const AllNotifications = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [isGettingDetails, setIsGettingDetails] = useState<Boolean>(false)
  const [notifications, setNotifications] = useState<any>([])

  useEffect(() => {
    const getAll = async () => {
      setIsGettingDetails(true)
      await getNotifications(
        currentUser?.uid,
        (res: any) => {
          setNotifications(res)
          setIsGettingDetails(false)
        },
        (error: any) => {
          console.log(error)
          setIsGettingDetails(false)
        }
      )
    }
    getAll()
  }, [])

  const handleReadMessage = (item: any) => {
    if (!item.isRead) {
      readNotification(item.id)
    }

    if (item?.redirectUrl) {
      let stateObj = {}
      if (item?.pageTitle) {
        stateObj = {...stateObj, pageTitle: item?.pageTitle}
      }
      if (item?.pageDescription) {
        stateObj = {...stateObj, pageDescription: item?.pageDescription}
      }

      if (item?.hoverDescription) {
        stateObj = {...stateObj, hoverDescription: item?.hoverDescription}
      }

      navigate(item?.redirectUrl, {
        state: stateObj,
      })
    }
  }

  return (
    <>
      <div className='d-md-flex justify-content-between align-items-center my-lg-5 my-0'>
        {/* <div className='breadcrumb-heading'>
          <h2 className='text-white mb-0'>
            Notifications
            {' > '}
            View all notifications
          </h2>
        </div> */}

        <BreadCrum defaultTitle='Notifications' defaultDescription='Notifications > View All' />
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer border-bottom border-2'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Notifications</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          {isGettingDetails ? (
            <div className='d-flex justify-content-center py-5 px-2'>
              <Loader />
            </div>
          ) : (
            <div className='row g-0'>
              <div className=' col-12'>
                <div className='my-5 px-8'>
                  {notifications.length === 0 && (
                    <h6 className='fs-6 text-gray-800 mb-0 fw-bold'>No live No Live Search</h6>
                  )}
                  {notifications.map((item: any, index: any) => (
                    <div
                      key={index}
                      className={`d-flex flex-stack ${
                        item.isRead ? 'border' : 'bg-light'
                      } py-4 mb-2 rounded px-4 cursor-pointer`}
                      onClick={() => handleReadMessage(item)}
                    >
                      <div className='mb-0 me-2'>
                        <h6 className='fs-6 text-gray-800 mb-0 text-hover-primary fw-bold'>
                          {item.userName}
                        </h6>
                        <div className='text-gray-700 fs-7'>{item.message}  at {formatCreatedAt(item?.createdAt)}</div>
                        {/* {!item.isRead && (
                          <p className='text-gray-700 fs-7 mt-4 mb-0'><strong>Status:</strong>  Unread</p>
                        )} */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AllNotifications
