import {Column} from 'react-table'
import {ContactCompanyCell} from './ContactCompanyCell'
import {ContactEmailCell} from './ContactEmailCell'
import {ContactActionsCell} from './ContactActionsCell'
import {ContactInfoCell} from './ContactInfoCell'
import {ContactPnumberCell} from './ContactPnumberCell'
import {ContactCustomHeader} from './ContactCustomHeader'

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title='Contact' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ContactInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    Cell: ({...props}) => <ContactEmailCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title='Company' className='min-w-125px' />
    ),
    id: 'company',
    Cell: ({...props}) => <ContactCompanyCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title='Telephone' className='min-w-125px' />
    ),
    id: 'telephone',
    Cell: ({...props}) => <ContactPnumberCell user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <ContactCustomHeader tableProps={props} className='text-end min-w-100px' />,
  //   id: 'actions',
  //   Cell: ({...props}) => (
  //     <ContactActionsCell
  //       id={props.data[props.row.index].id}
  //       company={props.data[props.row.index]}
  //     />
  //   ),
  // },
]

export {usersColumns}
