import {useEffect, useState} from 'react'
import {BsSearch} from 'react-icons/bs'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {formatedDate} from '../../../../_metronic/helpers/FormatDate'
import {handleVideoStatus} from '../../../../_metronic/helpers/VideoStatus'
import {useAuth} from '../../auth'
import {getSearchData} from '../../auth/core/_requests'
import {ContactListWrapper} from './ContactListWrapper'
import {SearchOpportunityWrapper} from './SearchOpportunityWrapper'
import {OpportunityTable} from './requestTable/OpportunityTable'
import Loader from '../../accounts/components/Loading/Loader'
import BreadCrum from '../global/BreadCrum'

const Overview = () => {
  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState('videos')
  const navigate = useNavigate()

  const location = useLocation()
  console.log('location: ', location)

  const [searchQuery, setSearchQuery] = useState<any>('')
  const [searchData, setSearchData] = useState<{
    opportunities: any[]
    videos: any[]
    contacts: any[]
  } | null>(null)
  console.log('<<<searchData>>: ', searchData)

  const [filteredContacts, setFilteredContacts] = useState<any>([])
  const [filteredSendOpportunities, setFilteredSendOpportunities] = useState([])
  const [filteredRequestOpportunities, setFilteredRequestOpportunities] = useState([])
  const [filteredVideos, setFilteredVideos] = useState([])
  const filterData = (query: string) => {
    console.log('query: ', query)
    if (query) {
      let filteredContact: any = searchData?.contacts.filter((contact) => {
        let name = contact.firstName + ' ' + contact.lastName
        return name.toLowerCase().includes(query.toLowerCase())
        // || contact.lastName.toLowerCase().includes(query.toLowerCase())
      })
      setFilteredContacts(filteredContact)
      let filteredSendOpportunitie: any = searchData?.opportunities.filter((opportunity) => {
        return (
          opportunity.opportunityName.toLowerCase().includes(query.toLowerCase()) &&
          opportunity?.opportunityType === 'sendVideo'
        )
      })
      setFilteredSendOpportunities(filteredSendOpportunitie)
      let filteredRequestOpportunitie: any = searchData?.opportunities.filter((opportunity) => {
        return (
          opportunity.opportunityName.toLowerCase().includes(query.toLowerCase()) &&
          opportunity?.opportunityType === 'requestVideo'
        )
      })
      setFilteredRequestOpportunities(filteredRequestOpportunitie)
      let filteredVideo: any = searchData?.videos.filter((video) => {
        return video.videoTitle.toLowerCase().includes(query.toLowerCase())
      })
      setFilteredVideos(filteredVideo)
      if (filteredVideo?.length === 0 && filteredSendOpportunitie?.length !== 0) {
        setActiveTab('send opportunities')
      } else if (
        filteredVideo?.length === 0 &&
        filteredSendOpportunitie?.length === 0 &&
        filteredRequestOpportunitie?.length !== 0
      ) {
        setActiveTab('request opportunities')
      } else if (
        filteredVideo?.length === 0 &&
        filteredSendOpportunitie?.length === 0 &&
        filteredRequestOpportunitie?.length === 0 &&
        filteredContact?.length !== 0
      ) {
        setActiveTab('contacts')
      }
    }
  }
  console.log(searchData, filteredContacts)

  useEffect(() => {
    if (!searchQuery) {
      setFilteredContacts([])
      setFilteredRequestOpportunities([])
      setFilteredSendOpportunities([])
      setFilteredVideos([])
    }
  }, [searchQuery])

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const fetchData = async () => {
        setIsLoading(true)
        const data = await getSearchData(currentUser?.uid)
        setSearchData(data)
        setIsLoading(false)
      }
      fetchData()
    }
  }, [currentUser])

  useEffect(() => {
    console.log('location.state:?>>>> ', location.state)
    if (location && location.state) {
      let searchStateQuery = location.state as string
      setSearchQuery(searchStateQuery)
      filterData(searchStateQuery)
    }
  }, [location, searchData])

  return (
    <>
      <div className='d-md-flex justify-content-between align-items-center my-lg-5 my-0'>
        {/* <div className='breadcrumb-heading'>
          <h2 className='text-white mb-0'>Search</h2>
        </div> */}
        <BreadCrum defaultTitle={`${activeTab}`} defaultDescription={`Search > ${activeTab}`} />
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='container p-6'>
            <h2 className='mb-3'>Search:</h2>
            <div className='row align-items-center '>
              <div className='col-md-6 col-8'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Search...'
                  name='query'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className='col-md-4 col-3'>
                <button
                  className='btn btn-primary d-flex justify-content-center align-items-center gap-2'
                  onClick={() => filterData(searchQuery)}
                >
                  <BsSearch size={18} color='#fff' />
                  Search
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='my-5'>
                <div className='d-flex flex-wrap gap-6 align-items-center'>
                  <div
                    className='d-flex gap-2 align-items-center'
                    onClick={() => setActiveTab('videos')}
                  >
                    <input
                      type='radio'
                      className='search-checkbox'
                      id='videos'
                      name='type'
                      value='videos'
                      checked={activeTab === 'videos'}
                      defaultChecked
                    />
                    <label
                      htmlFor='videos'
                      className={`${
                        activeTab === 'videos' ? 'text-pink' : 'text-gray-500'
                      } fw-bold cursor-pointer`}
                    >
                      {/* <span
                    className='btn btn-sm btn-color-muted btn-active btn-active-primary' 
                  > */}
                      {`Videos (${filteredVideos?.length})`}
                      {/* </span> */}
                    </label>
                  </div>
                  <div
                    className='d-flex gap-2 align-items-center'
                    onClick={() => setActiveTab('send opportunities')}
                  >
                    <input
                      type='radio'
                      id='opportunities'
                      className='search-checkbox'
                      name='type'
                      checked={activeTab === 'send opportunities'}
                      value='opportunities'
                    />
                    <label
                      htmlFor='opportunities'
                      className={`${
                        activeTab === 'send opportunities' ? 'text-pink' : 'text-gray-500'
                      } fw-bold cursor-pointer`}
                    >
                      {`Sent-Opportunities (${filteredSendOpportunities?.length})`}
                    </label>
                  </div>
                  <div
                    className='d-flex gap-2 align-items-center'
                    onClick={() => setActiveTab('request opportunities')}
                  >
                    <input
                      type='radio'
                      id='request'
                      className='search-checkbox'
                      name='type'
                      checked={activeTab === 'request opportunities'}
                      value='opportunities'
                    />
                    <label
                      htmlFor='request'
                      className={`${
                        activeTab === 'request opportunities' ? 'text-pink' : 'text-gray-500'
                      } fw-bold cursor-pointer`}
                    >
                      {`Received-Opportunities (${filteredRequestOpportunities?.length})`}
                    </label>
                  </div>

                  <div
                    className='d-flex gap-2 align-items-center'
                    onClick={() => setActiveTab('contacts')}
                  >
                    <input
                      type='radio'
                      id='contact'
                      className='search-checkbox'
                      name='type'
                      checked={activeTab === 'contacts'}
                      value='contacts'
                    />
                    <label
                      htmlFor='contact'
                      className={`${
                        activeTab === 'contacts' ? 'text-pink' : 'text-gray-500'
                      } fw-bold cursor-pointer`}
                    >
                      {`Contacts (${filteredContacts?.length})`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {searchQuery !== '' ? (
              <div className='row'>
                {activeTab === 'videos' && (
                  <div className='container-fluid my-5 px-0'>
                    <div className='row'>
                      {filteredVideos && filteredVideos?.length > 0 ? (
                        <>
                          {filteredVideos?.map((item: any, index: number) => {
                            return (
                              <div
                                // to={`/videoMessages/view-video/${item.id}`}
                                className='col-xl-3 col-md-4 col-xs-6 d-flex align-items-stretch p-3 cursor-pointer'
                                key={index}
                                onClick={() => {
                                  navigate(`/videoMessages/view-video/${item.id}`, {
                                    state: {
                                      pageTitle: item?.videoTitle,
                                      pageDescription: `Search > ... > ${item?.videoTitle}`,
                                      hoverDescription: `Search > ${activeTab} > ${item?.videoTitle}`,
                                    },
                                  })
                                }}
                              >
                                <div className='card shadow-sm video__card'>
                                  <video
                                    controls={true}
                                    className='card-img-top w-100 video-container p-5 pb-0'
                                  >
                                    <source src={item?.videoUrl} type='video/mp4' />
                                  </video>

                                  <div className='card-body p-5'>
                                    <div className='mb-4'>
                                      <h5 className='text-pink fw-bolder'>{item?.videoTitle}</h5>
                                    </div>
                                    <div className='row my-2'>
                                      <span className='col-4 text-blue fw-bold'>Date</span>
                                      <span className='col-8 text-body-text'>
                                        {formatedDate(item?.createdAt)}
                                      </span>
                                    </div>
                                    {/* <div className='row my-2'>
                            <span className='col-4 text-blue fw-bold'>Recipient</span>
                            <span className='col-8 text-body-text'>John</span>
                          </div> */}
                                    <div className='row my-2'>
                                      <span className='col-4 text-blue fw-bold'>Status</span>
                                      <span className='col-8  d-flex'>
                                        <div className=' py-1 px-2 rounded'>
                                          {handleVideoStatus(item)}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <div className='py-8 d-flex flex-column align-items-center  justify-content-center'>
                          <img
                            width={48}
                            height={48}
                            className='my-5'
                            src={toAbsoluteUrl('/media/svg/landscape-modals/exclamation.svg')}
                            alt='icon'
                          />
                          <p className='text-gray-500 fw-bold '>No search results found</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === 'request opportunities' && (
                  <div className='container-fluid my-5 px-0'>
                    {filteredRequestOpportunities && filteredRequestOpportunities?.length > 0 ? (
                      <>
                        {/* <SearchOpportunityWrapper data={filteredSendOpportunities} /> */}
                        <OpportunityTable data={filteredRequestOpportunities} />
                      </>
                    ) : (
                      <div className='py-8 d-flex flex-column align-items-center  justify-content-center'>
                        <img
                          width={48}
                          height={48}
                          className='my-5'
                          src={toAbsoluteUrl('/media/svg/landscape-modals/exclamation.svg')}
                          alt='icon'
                        />
                        <p className='text-gray-500 fw-bold '>No search results found</p>
                      </div>
                    )}
                  </div>
                )}
                {activeTab === 'send opportunities' && (
                  <div className='container-fluid my-5 px-0'>
                    {filteredSendOpportunities && filteredSendOpportunities?.length > 0 ? (
                      <>
                        <SearchOpportunityWrapper data={filteredSendOpportunities} />
                      </>
                    ) : (
                      <div className='py-8 d-flex flex-column align-items-center  justify-content-center'>
                        <img
                          width={48}
                          height={48}
                          className='my-5'
                          src={toAbsoluteUrl('/media/svg/landscape-modals/exclamation.svg')}
                          alt='icon'
                        />
                        <p className='text-gray-500 fw-bold '>No search results found</p>
                      </div>
                    )}

                    {/* <OpportunityListWrapper /> */}
                  </div>
                )}
                {activeTab === 'contacts' && (
                  <div className='container-fluid my-5 px-0'>
                    {filteredContacts && filteredContacts?.length > 0 ? (
                      <div className='container'>
                        <ContactListWrapper data={filteredContacts} />
                      </div>
                    ) : (
                      <div className='py-8 d-flex flex-column align-items-center  justify-content-center'>
                        <img
                          width={48}
                          height={48}
                          className='my-5'
                          src={toAbsoluteUrl('/media/svg/landscape-modals/exclamation.svg')}
                          alt='icon'
                        />
                        <p className='text-gray-500 fw-bold '>No search results found</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className='py-8 d-flex flex-column align-items-center  justify-content-center'>
                <img
                  width={48}
                  height={48}
                  className='my-5'
                  src={toAbsoluteUrl('/media/svg/landscape-modals/exclamation.svg')}
                  alt='icon'
                />
                <p className='text-gray-500 fw-bold '>No search results found</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Overview
