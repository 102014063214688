import Repository from './Repository'
import {v4 as uuidv4} from 'uuid'

export default {
  uploadVideo(payload: any) {
    const formData = new FormData()
    // let filename = uuidv4() + '.mp4'
    // const file = new File([payload], filename)
    console.log('Form daat', formData, payload)
    formData.append('file', payload)

    return Repository.post('/video/upload', formData)
  },
  uploadTrimVideo(url: string, allowCaptions: boolean) {
    return Repository.post('/video/upload/trim', {url, allowCaptions})
  },
}
