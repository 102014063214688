/* eslint-disable jsx-a11y/anchor-is-valid */
import ActivityVideos from './ActivityVideos'
import {useQueryResponseData} from './opportunity-list/core/QueryResponseProvider'
import {calculateSumForStats} from '../../../../_metronic/helpers/calculateSumForStats'
import {useEffect, useState} from 'react'
import {getRecordedVideos} from './opportunity-list/core/_requests'
import {useAuth} from '../../auth'
import RequestedVideos from './RequestedVideos'
import Loader from '../../accounts/components/Loading/Loader'
import {filterRecentVideos} from '../../../../_metronic/helpers/filterRecentVideos'
import ApexChart from './RadialChart'
import RadialChartOverviewOpportunities from './RadialChartOverviewOpportunities'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import BreadCrum from '../../apps/global/BreadCrum'

export const Activity = () => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('sendVideo')
  const [opportunityData, setOpportunityData] = useState<any>([])
  const [filteredValue, setFilteredValue] = useState('all')

  const [recentVideos, setRecentVideos] = useState<any>([])
  const [sendFilteredVideos, setSendFilteredVideos] = useState<any>([])
  const [requestedVideos, setRequestedVideos] = useState<any>([])
  const [requestFilteredVideos, setRequestFilteredVideos] = useState<any>([])

  let opportunities = useQueryResponseData()
  opportunities = opportunities.filter((item) => item.opportunityType === activeTab)

  useEffect(() => {
    setIsLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const filterParam = urlParams.get('filter')
    const tabParam = urlParams.get('tab')
    if (tabParam) {
      if (tabParam == 'send-video') {
        setActiveTab('sendVideo')
      } else if (tabParam == 'request-video') {
        setActiveTab('requestVideo')
      } else {
        setActiveTab('sendVideo')
      }
    } else {
      setActiveTab('sendVideo')
    }
    if (filterParam) {
      setFilteredValue(filterParam)
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    setOpportunityData(opportunities.filter((item) => item.opportunityType === activeTab))
  }, [activeTab, filteredValue])

  useEffect(() => {
    setSendFilteredVideos(filterRecentVideos(recentVideos, filteredValue))
  }, [recentVideos])
  useEffect(() => {
    setRequestFilteredVideos(filterRecentVideos(requestedVideos, filteredValue))
  }, [requestedVideos])
  useEffect(() => {
    const getRecentVideos = async () => {
      setIsLoading(true)
      const res = await getRecordedVideos(currentUser?.uid, activeTab)
      if (activeTab === 'sendVideo') {
        setRecentVideos(res)
        setSendFilteredVideos(filterRecentVideos(recentVideos, filteredValue))
        setIsLoading(false)
      } else {
        setRequestedVideos(res)
        setRequestFilteredVideos(filterRecentVideos(requestedVideos, filteredValue))
        setIsLoading(false)
      }
    }
    getRecentVideos()
  }, [activeTab])

  useEffect(() => {
    setIsLoading(true)
    if (activeTab === 'sendVideo') {
      setSendFilteredVideos(filterRecentVideos(recentVideos, filteredValue))
    }
    if (activeTab === 'requestVideo') {
      setRequestFilteredVideos(filterRecentVideos(requestedVideos, filteredValue))
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [filteredValue])
  console.log('filteredValue', opportunityData)

  const sendVideoData = [
    {
      id: 1,
      title: 'Opportunities',
      number: opportunities.length,
      color: 'success',
    },
    {
      id: 2,
      title: 'Messages sent',
      number: calculateSumForStats(opportunities, 'videosSent'),
      color: 'primary',
    },
    {
      id: 3,
      title: 'Videos opened',
      number: calculateSumForStats(opportunities, 'videosOpened'),
      color: 'info',
    },
    {
      id: 4,
      title: 'Videos viewed in full',
      number: calculateSumForStats(opportunities, 'videosViewed'),
      color: 'success',
    },
    {
      id: 5,
      title: 'Video not opened',
      number: calculateSumForStats(opportunities, 'videosNotOpened'),
      color: 'warning',
    },
    {
      id: 6,
      title: 'Interested',
      number: calculateSumForStats(opportunities, 'interested'),
      color: 'success',
    },
    {
      id: 7,
      title: 'Not interested',
      number: calculateSumForStats(opportunities, 'notInterested'),
      color: 'danger',
    },
  ]

  const data = [
    {
      id: 1,
      title: 'Opportunities',
      number: opportunities.length,
      color: 'dark',
    },
    {
      id: 2,
      title: 'Videos requested',
      number: calculateSumForStats(opportunities, 'videosRequested'),
      color: 'success',
    },
    {
      id: 3,
      title: 'Videos received',
      number: calculateSumForStats(opportunities, 'videosReceived'),
      color: 'warning',
    },
    {
      id: 4,
      title: 'Videos viewed in full',
      number: calculateSumForStats(opportunities, 'videosViewed'),
      color: 'success',
    },
    {
      id: 5,
      title: 'Video not opened',
      number: calculateSumForStats(opportunities, 'videosNotOpened'),
      color: 'danger',
    },
  ]

  const videosSent: any = sendVideoData
    .filter((item) => item.title === 'Messages sent')
    .map((item) => item.number)
  const statsArray = sendVideoData
    .filter(
      (item) =>
        item.title !== 'Opportunities' &&
        item.title !== 'Messages sent' &&
        item.title !== 'Videos opened'
    )
    .map((item) => (videosSent > 0 ? ((item.number * 100) / videosSent).toFixed() : item.number))

  const handleFilterValue = (title: string) => {
    if (title === 'Opportunities') {
      setFilteredValue('all')
    } else if (title === 'Messages sent') {
      setFilteredValue('all')
    } else if (title === 'Videos opened') {
      setFilteredValue('opened')
    } else if (title === 'Videos viewed') {
      setFilteredValue('viewed')
    } else if (title === 'Video not opened') {
      setFilteredValue('not-opened')
    } else if (title === 'Interested') {
      setFilteredValue('interested')
    } else if (title === 'Not interested') {
      setFilteredValue('not-interested')
    } else if (title === 'Videos received') {
      setFilteredValue('all')
    }
  }

  // console.log('Stats.. array', statsArray)

  const videoRequested: any = data
    .filter((item) => item.title === 'Videos requested')
    .map((item) => item.number)
  const requestStatsArray = data
    .filter((item) => item.title !== 'Opportunities')
    .map((item) =>
      item.title === 'Videos requested'
        ? item.number
        : videoRequested > 0
        ? ((item.number * 100) / videoRequested).toFixed()
        : item.number
    )

  const getFill = () => {
    return activeTab !== 'sendVideo' ? '#00a0fd' : 'white'
  }

  return (
    <>
      {/* <div className='d-md-flex justify-content-between align-items-center my-lg-5 my-0'>
        <div className='breadcrumb-heading'>
          <h2 className='text-white mb-0'>
            Activity - {activeTab == 'sendVideo' ? 'Videos Sent' : 'Videos Requested'}{' '}
          </h2>
        </div>
      </div> */}

      <div className='d-md-flex justify-content-between align-items-center mb-0 mb-md-5  mt-md-5 btn-section'>
        <BreadCrum
          // pageTitle={`${activeTab == 'sendVideo' ? 'Videos Sent' : 'Videos Requested'}`}
          // pageDescription={`Dashboard ${
          //   activeTab == 'sendVideo' ? '> Videos Sent' : '> Videos Requested'
          // }`}
          defaultTitle='Dashboard'
          defaultDescription={`Dashboard ${
            activeTab == 'sendVideo' ? '> Videos Sent' : '> Videos Requested'
          }`}
        />

        {/* <div className='breadcrumb-heading d-flex flex-column'>
          <h2 className='text-white mb-0'>
            {' '}
            Dashboard {activeTab == 'sendVideo' ? '> Videos Sent' : '> Videos Requested'}{' '}
          </h2>
        </div> */}
        <div className='d-flex gap-2 justify-content-start  justify-content-md-center align-items-center'>
          <button
            className={`btn ${
              activeTab == 'sendVideo' ? 'btn-pink' : 'bg-white text-primary btn-secondary'
            }  order-md-1 order-2  d-flex align-items-center gap-2 align-self-center`}
            onClick={() => {
              setActiveTab('sendVideo')
              setFilteredValue('all')
            }}
          >
            Videos Sent{' '}
            <img
              alt='Logo'
              src={
                activeTab == 'sendVideo'
                  ? toAbsoluteUrl('/media/icons/sendvideo.svg')
                  : toAbsoluteUrl('/media/icons/sendVideoPrimary.svg')
              }
              className='logo-sticky h-15px'
              // style={{fill: getFill()}}
            />
            {/* <i className='fa-regular fa-circle-play mb-1'></i> */}
          </button>
          <button
            className={`btn ${
              activeTab == 'requestVideo' ? 'btn-pink' : 'bg-white text-primary btn-secondary'
            } btn-primary order-md-2 order-1 align-self-center d-flex align-items-center gap-2`}
            onClick={() => {
              setActiveTab('requestVideo')
              setFilteredValue('all')
            }}
          >
            Videos Requested{' '}
            <img
              alt='Logo'
              src={
                activeTab == 'requestVideo'
                  ? toAbsoluteUrl('/media/icons/requestvideo.svg')
                  : toAbsoluteUrl('/media/icons/requestVideoPrimary.svg')
              }
              className='logo-sticky h-15px'
            />
            {/* <i className='fa-regular fa-plus mb-1'></i> */}
          </button>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer border-bottom border-2'>
          <div className='container my-5'>
            <div className='row justify-content-between  align-items-center '>
              {/* <div className='my-5 col-lg-4 col-sm-8'>
                <div className='nav-group nav-group-fluid'>
                  <label>
                    <input
                      type='radio'
                      className='btn-check'
                      name='type'
                      value='sendVideo'
                      checked={activeTab === 'sendVideo'}
                    />
                    <span
                      className='btn btn-sm btn-color-muted btn-active btn-active-primary px-sm-4 px-2'
                      onClick={() => {
                        setActiveTab('sendVideo')
                        setFilteredValue('all')
                      }}
                    >
                      Videos sent
                    </span>
                  </label>

                  <label>
                    <input
                      type='radio'
                      className='btn-check'
                      name='type'
                      value='requestVideo'
                      checked={activeTab === 'requestVideo'}
                    />
                    <span
                      className='btn btn-sm btn-color-muted btn-active btn-active-primary px-sm-4 px-2'
                      onClick={() => {
                        setActiveTab('requestVideo')
                        setFilteredValue('all')
                      }}
                    >
                      Video requests
                    </span>
                  </label>
                </div>
              </div> */}
              <div className='col-12 d-flex justify-content-sm-end'>
                {activeTab === 'sendVideo' && (
                  <div className='dropdown'>
                    <button
                      className='btn btn-light-primary dropdown-toggle text-capitalize '
                      type='button'
                      id='dropdownMenuButton1'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {filteredValue}
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                      <li className='dropdown-item' onClick={() => setFilteredValue('all')}>
                        All
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('opened')}>
                        Opened
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('not-opened')}>
                        Not Opened
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('viewed')}>
                        Viewed
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('interested')}>
                        Interested
                      </li>
                      <li
                        className='dropdown-item'
                        onClick={() => setFilteredValue('not-interested')}
                      >
                        Not Interested
                      </li>
                    </ul>
                  </div>
                )}
                {activeTab === 'requestVideo' && (
                  <div className='dropdown'>
                    <button
                      className='btn btn-light-primary dropdown-toggle text-capitalize '
                      type='button'
                      id='dropdownMenuButton1'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {filteredValue}
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                      <li className='dropdown-item' onClick={() => setFilteredValue('all')}>
                        All
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('opened')}>
                        Opened
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('not-opened')}>
                        Not Opened
                      </li>
                      <li className='dropdown-item' onClick={() => setFilteredValue('viewed')}>
                        Viewed
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {activeTab === 'sendVideo' && (
              <div className='row'>
                <div className='col-lg-6'>
                  <ApexChart statsArray={statsArray} />
                </div>
                <div className='col-lg-6'>
                  <div className='row'>
                    {sendVideoData?.map((item: any) => {
                      return (
                        <div className=' col-md-4 col-xs-6 p-2'>
                          <div
                            className='card border border-gray-300 border-dotted cursor-pointer'
                            // onClick={() => handleFilterValue(item.title)}
                          >
                            <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                              <h1 className={`display-6 fw-normal text-${item?.color}`}>
                                {item.number}
                              </h1>
                              <span className='text-nowrap'>{item.title}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {/* {sendVideoData?.map((item: any) => {
                  return (
                    <div className='col-lg-2 col-md-2 col-xs-6 p-2'>
                      <div
                        className='card border border-gray-300 border-dotted cursor-pointer'
                        onClick={() => handleFilterValue(item.title)}
                      >
                        <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                          <h1>{item.number}</h1>
                          <span className='text-nowrap'>{item.title}</span>
                        </div>
                      </div>
                    </div>
                  )
                })} */}
              </div>
            )}
            {activeTab === 'requestVideo' && (
              <div className='row'>
                <div className='col-lg-6'>
                  <RadialChartOverviewOpportunities statsArray={requestStatsArray} />
                </div>
                <div className='col-lg-6'>
                  <div className='row'>
                    {data?.map((item: any) => {
                      return (
                        <div className=' col-md-4 col-xs-6 p-2'>
                          <div
                            className='card border border-gray-300 border-dotted cursor-pointer'
                            // onClick={() => handleFilterValue(item.title)}
                          >
                            <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                              <h1 className={`display-6 fw-normal text-${item?.color}`}>
                                {item.number}
                              </h1>
                              <span className='text-nowrap'>{item.title}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                {/* {data?.map((item) => {
                  return (
                    <div className='col-lg-2 col-md-2 col-xs-6 p-2'>
                      <div
                        className='card border border-gray-300 border-dotted cursor-pointer'
                        onClick={() => handleFilterValue(item.title)}
                      >
                        <div className='card-body d-flex justify-content-center align-items-center flex-column'>
                          <h1>{item.number}</h1>
                          <span className='text-nowrap'>{item.title}</span>
                        </div>
                      </div>
                    </div>
                  )
                })} */}
              </div>
            )}
          </div>
        </div>
      </div>

      {recentVideos.length > 0 && activeTab === 'sendVideo' && (
        <h3 className='mb-4'>Recent Videos</h3>
      )}
      {requestedVideos.length > 0 && activeTab === 'requestVideo' && (
        <h3 className='mb-4'>Recent Videos</h3>
      )}
      {isLoading && <Loader />}
      {!isLoading && recentVideos.length > 0 && activeTab === 'sendVideo' && (
        <>
          {sendFilteredVideos.length > 0 ? (
            <ActivityVideos recentVideos={sendFilteredVideos.slice(0, 8)} />
          ) : (
            <h2 className='text-gray-500'>Sorry, we couldn't find any matching videos.</h2>
          )}
        </>
      )}
      {!isLoading && requestedVideos.length > 0 && activeTab === 'requestVideo' && (
        <>
          {requestFilteredVideos.length > 0 ? (
            <RequestedVideos data={requestFilteredVideos.slice(0, 8)} />
          ) : (
            <h2 className='text-gray-500'>Sorry, we couldn't find any matching videos.</h2>
          )}
        </>
      )}
    </>
  )
}
