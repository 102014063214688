/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  opened: any
}

const OpportunityOpenedCell: FC<Props> = ({opened}) => (
  <>
    <div className=''>{opened?.opportunityStats.videosOpened}</div>
  </>
)

export {OpportunityOpenedCell}
