/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  interested: any
}

const OpportunityInterestedCell: FC<Props> = ({interested}) => (
  <>
    <div className=''>{interested?.opportunityStats.interested}</div>
  </>
)

export {OpportunityInterestedCell}
