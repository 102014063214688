import {Column} from 'react-table'
import {User} from '../../../user-management/users-list/core/_models'
import {OpportunityCustomHeader} from './OpportunityCustomHeader'
import {OpportunityCell} from './OpportunityCell'
import {OpportunityVideoCell} from './OpportunityVideoCell'
import {OpportunityViewCell} from './OpportunityViewCell'
import {OpportunityOpenedCell} from './OpportunityOpenedCell'
import {OpportunityUnopenedCell} from './OpportunityUnopenedCell'
import {OpportunityNotInterestedCell} from './OpportunityNotInterestedCell'
import {OpportunityInfoReqCell} from './OpportunityInfoReqCell'
import {OpportunityInterestedCell} from './OpportunityInterestedCell'
import {OpportunityActionsCell} from './OpportunityActionsCell'

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Opportunity' className='min-w-125px' />
    ),
    id: 'opportunity',
    Cell: ({...props}) => <OpportunityCell opportunity={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Videos sent' className='min-w-125px' />
    ),
    id: 'videosent',
    Cell: ({...props}) => <OpportunityVideoCell videosent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Videos opened' className='min-w-125px' />
    ),
    id: 'opened',
    Cell: ({...props}) => <OpportunityOpenedCell opened={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='videos viewed' className='min-w-125px' />
    ),
    id: 'viewed',
    Cell: ({...props}) => <OpportunityViewCell viewed={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader
        tableProps={props}
        title='Videos un-opened'
        className='min-w-125px'
      />
    ),
    id: 'unopened',
    Cell: ({...props}) => <OpportunityUnopenedCell unopened={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Interested' className='min-w-125px' />
    ),
    id: 'interested',
    Cell: ({...props}) => <OpportunityInterestedCell interested={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Not interested' className='min-w-125px' />
    ),
    id: 'notinterested',
    Cell: ({...props}) => (
      <OpportunityNotInterestedCell notInterested={props.data[props.row.index]} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <OpportunityCustomHeader tableProps={props} className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => (
  //     <OpportunityActionsCell
  //       id={props.data[props.row.index].id}
  //       type={props?.data[props.row.index]?.opportunityType}
  //     />
  //   ),
  // },
]

export {usersColumns}
