import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import firebase from 'firebase/app'
import moment from 'moment'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

// ===========GET CONTACTS============
// const getUsers = (query: string): Promise<UsersQueryResponse> => {
//   return axios
//     .get(`${GET_USERS_URL}?${query}`)
//     .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

const getContacts: any = async (query: string) => {
  try {
    const currentUser = firebase.auth().currentUser
    const contactsSnapshot = await firebase
      .firestore()
      .collection('contacts')
      .where('createdById', '==', currentUser?.uid)
      .get()

    const contactsData: any = contactsSnapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    let filteredData = contactsData
    filteredData.sort((a: any, b: any) => b.createdAt - a.createdAt)

    if (query.includes('&search=') && !query.includes('&filter_role=')) {
      const searchTerm = query.split('&search=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.name.toLowerCase().includes(searchTerm)
      )
    }

    if (query.includes('&filter_role=') && !query.includes('&search=')) {
      const filterTerm = query.split('&filter_role=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.companyName.toLowerCase().includes(filterTerm)
      )
    }

    if (query.includes('&search=') && query.includes('&filter_role=')) {
      if (query.split('&filter_role=')[1].length > 0) {
        const filterTerm = query.split('&filter_role=')[1].toLowerCase()
        let newFilteredData = filteredData.filter((data: any) =>
          data.companyName.toLowerCase().includes(filterTerm)
        )
        // const searchTerm = query.split('&search=')[1].toLowerCase()
        const dynamicValueString = query.split('&search=')[1]
        const dynamicValue = dynamicValueString.split('&filter_role=')[0].toLowerCase()

        filteredData = newFilteredData.filter((data: any) =>
          data.name.toLowerCase().includes(dynamicValue)
        )
      }
    }
    // HANDLE PAGINATION
    const page = parseInt(query.split('page=')[1].split('&')[0])
    const itemsPerPage = parseInt(query.split('items_per_page=')[1])
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    // GENERATING PAGINATION LINKS ARRAY DYNAMICALLY
    const paginatedData = filteredData.slice(startIndex, endIndex)
    const links = []
    if (totalPages > 0) {
      // PREVIOUS BUTTON
      links.push({
        url: page > 1 ? `/?page=${page - 1}` : null,
        label: '&laquo; Previous',
        active: false,
        page: page > 1 ? page - 1 : null,
      })

      // PAGE NUMBERS
      for (let i = 1; i <= totalPages; i++) {
        const url = `/?page=${i}`
        const label = i.toString()
        const active = i === page
        links.push({url, label, active, page: i})
      }

      // NEXT BUTTON
      links.push({
        url: page < totalPages ? `/?page=${page + 1}` : null,
        label: 'Next &raquo;',
        active: false,
        page: page < totalPages ? page + 1 : null,
      })
    }

    return {
      data: paginatedData,
      payload: {
        pagination: {
          first_page_url: `/?page=1`,
          from: startIndex + 1,
          items_per_page: itemsPerPage,
          last_page: totalPages,
          links,
          next_page_url: page < totalPages ? `/?page=${page + 1}` : null,
          page,
          prev_page_url: page > 1 ? `/?page=${page - 1}` : null,
          to: endIndex,
          total: filteredData.length,
        },
      },
    }
  } catch (error) {
    console.error('Error getting contacts: ', error)
  }
}

// ===========GET SINGLE CONTACT============
// const getUserById = (id: ID): Promise<User | undefined> => {
//   return axios
//     .get(`${USER_URL}/${id}`)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }
const getContactById: any = async (id: ID) => {
  try {
    const contactSnapshot = await firebase.firestore().collection('contacts').doc(id).get()

    if (!contactSnapshot.exists) {
      throw new Error('Contact not found')
    }

    const contactData = {
      id: contactSnapshot.id,
      ...contactSnapshot.data(),
    }
    // console.log(contactData)
    return {data: contactData}
  } catch (error) {
    console.error('Error getting contact: ', error)
  }
}

// ===========CREATE CONTACT============
// const createUser = (user: User): Promise<User | undefined> => {
//   console.log("Create User")
//   return axios
//     .put(USER_URL, user)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }
const createContact = async (userInfo: User): Promise<string | void> => {
  try {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      throw new Error('No authenticated user found')
    }

    const newContactRef = await firebase
      .firestore()
      .collection('contacts')
      .add({
        name: userInfo.firstName + ' ' + userInfo.lastName,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        companyName: userInfo.companyName,
        telephone: userInfo?.telephone || '',
        createdAt: firebase.firestore.Timestamp.now(),
        createdById: currentUser.uid,
      })
    return newContactRef.id
  } catch (error) {
    console.error('Error adding user: ', error)
  }
}

// ===========UPDATE CONTACT============
// const updateUser = (user: User): Promise<User | undefined> => {
//   console.log(user)
//   return axios
//     .post(`${USER_URL}/${user.id}`, user)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }
const updateContact = async (userInfo: User): Promise<string | void> => {
  try {
    const contactRef = firebase.firestore().collection('contacts').doc(userInfo.id)
    let {id, ...rest} = userInfo
    await contactRef.update({
      ...rest,
      name: userInfo.firstName + ' ' + userInfo.lastName,
      updatedAt: firebase.firestore.Timestamp.now(),
    })
  } catch (error) {
    console.error('Error updating contact: ', error)
  }
}

// ===========DELETE CONTACT============
// const deleteUser = (userId: ID): Promise<void> => {
//   return axios.delete(`${USER_URL}/${userId}`).then(() => {})
// }
const deleteContact = async (documentId: ID) => {
  try {
    await firebase.firestore().collection('contacts').doc(documentId).delete()
    console.log('Document deleted successfully!')
  } catch (error) {
    console.error('Error deleting document: ', error)
  }
}

const deleteSelectedContacts = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getContactStatsAndVideos = async (id: ID, uid: ID) => {
  try {
    let query = await firebase
      .firestore()
      .collection('recordedVideos')
      .where('sendById', '==', uid)
      .where('sendTo', '==', id)
      .orderBy('createdAt', 'desc')
      .get()

    let tempStats = {
      messagesSent: 0,
      isOpened: 0,
      notOpened: 0,
      viewed: 0,
      interested: 0,
      notInterested: 0,
    }
    let tempVideoMessages = []

    tempStats = {...tempStats, messagesSent: query.size}

    for (let doc of query.docs) {
      let {isOpened, fullyWatched, recipientReaction} = doc.data()
      if (isOpened) {
        tempStats = {...tempStats, isOpened: tempStats.isOpened + 1}
      }
      if (!isOpened) {
        tempStats = {...tempStats, notOpened: tempStats.notOpened + 1}
      }
      if (fullyWatched) {
        tempStats = {...tempStats, viewed: tempStats.viewed + 1}
      }
      if (recipientReaction === 'not-interested') {
        tempStats = {...tempStats, notInterested: tempStats.notInterested + 1}
      }
      if (recipientReaction !== 'not-interested' && recipientReaction !== '') {
        tempStats = {...tempStats, interested: tempStats.interested + 1}
      }

      tempVideoMessages.push({id: doc.id, ...doc.data()})
    }

    return {
      tempStats,
      tempVideoMessages,
    }
  } catch (error: any) {
    alert(error?.message)
  }
}

const getContactStatsAndVideosInRange = async (id: ID, uid: ID, startDate: Date, endDate: Date) => {
  try {
    console.log('Stat', startDate, endDate)
    // const startingDate = moment(startDate).toDate()
    // const endingDate = moment(endDate).toDate()
    const startingDate = moment(startDate).toDate()
    const endingDate = moment(endDate).add(1, 'days').toDate()

    console.log('starting point', startingDate)
    console.log('ending point', endingDate)

    let query = await firebase
      .firestore()
      .collection('recordedVideos')
      .where('sendById', '==', uid)
      .where('sendTo', '==', id)
      .where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(startingDate))
      .where('createdAt', '<', firebase.firestore.Timestamp.fromDate(endingDate))
      .orderBy('createdAt', 'desc')
      .get()

    let tempStats = {
      messagesSent: 0,
      isOpened: 0,
      notOpened: 0,
      viewed: 0,
      interested: 0,
      notInterested: 0,
    }
    let tempVideoMessages = []

    tempStats = {...tempStats, messagesSent: query.size}

    for (let doc of query.docs) {
      let {isOpened, fullyWatched, recipientReaction} = doc.data()
      console.log('recipientReaction', recipientReaction)
      if (isOpened) {
        tempStats = {...tempStats, isOpened: tempStats.isOpened + 1}
      } else {
        tempStats = {...tempStats, notOpened: tempStats.notOpened + 1}
      }
      if (fullyWatched) {
        tempStats = {...tempStats, viewed: tempStats.viewed + 1}
      }
      if (recipientReaction === 'not-interested') {
        tempStats = {...tempStats, notInterested: tempStats.notInterested + 1}
      }
      if (recipientReaction !== 'not-interested' && recipientReaction !== '') {
        tempStats = {...tempStats, interested: tempStats.interested + 1}
      }

      tempVideoMessages.push({id: doc.id, ...doc.data()})
    }

    return {
      tempStats,
      tempVideoMessages,
    }
  } catch (error: any) {
    return
    alert(error?.message)
  }
}

export {
  getContacts,
  deleteContact,
  deleteSelectedContacts,
  getContactById,
  createContact,
  updateContact,
  getContactStatsAndVideos,
  getContactStatsAndVideosInRange,
}
