import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
// import phone_spin from '../../../../../../../public/media/svg/landscape-modals/phone_spin.svg'
import {Field, ErrorMessage} from 'formik'
import SVG from 'react-inlinesvg'
import {Button, Col, Row} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import VideoRecorder from '../../VideoRecorder'
import axios from 'axios'
import {getMySubscription} from '../../../../../accounts/core/_requests'
import {getAuth} from '../../../../../auth'
import {checkOpportunityDetails, uploadTrimVideo} from '../../../core/_requests'
import {clearPreviousVideoData} from '../../../deleteCloudinaryVideo'
import MessageAlert from '../../../../global/MessageAlert'
import Loader from '../../../../../accounts/components/Loading/Loader'
import AllowCaptionsSection from '../../../../global/AllowCaptionsSection'
const sha1 = require('js-sha1')

const Step2: FC<any> = ({
  title,
  setTitle,
  requestData,
  url,
  setCaptionProp,
  setUrl,
  submitStep,
  setShowBtn,
  subscriptionDetails,
  creatorDetails,
}) => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 600)
  let [currentStep, setCurrentStep] = useState('recordVideo')
  const [currentTime, setCurrentTime] = useState(0)
  const [isCheckingOpportunityDetails, setIsCheckingOpportunityDetails] = useState(false)
  const [allowCaptions, setAllowCaptions] = useState<boolean>(true)
  const handleCaptionChange = () => {
    setAllowCaptions((prevAllowCaptions) => !prevAllowCaptions)
  }
  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }

  // Alert Ends

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth <= 600)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  console.log(creatorDetails, 'creatorDetailscreatorDetails')

  const [show, setShow] = useState(false)
  const isOnFreePlan = () => {
    if (creatorDetails?.allowFreeAccess) {
      return false
    } else {
      if (
        !subscriptionDetails?.plan ||
        (subscriptionDetails?.plan && subscriptionDetails?.plan?.amount == 0)
        // (subscriptionDetails?.plan &&
        //   (subscriptionDetails?.plan?.nickname == 'Free Monthly' ||
        //     subscriptionDetails?.plan?.nickname == 'Free Annually'))
      ) {
        return true
      } else {
        return false
      }
    }
  }
  useEffect(() => {
    if (!isOnFreePlan()) {
      setAllowCaptions(true)
    } else {
      setAllowCaptions(false)
    }
  }, [creatorDetails, subscriptionDetails])

  const handleClose = () => {
    // localStorage.removeItem('animatedThumbnail')
    // localStorage.removeItem('animatedThumbnailUrl')
    // localStorage.removeItem('url')
    // localStorage.removeItem('video_start_offset')
    // localStorage.removeItem('video_end_offset')
    // localStorage.removeItem('thumbnail_end_offset')
    // localStorage.removeItem('thumbnail_start_offset')
    // localStorage.removeItem('trimmedUrlWithWatermark')
    // localStorage.removeItem('trimmedUrl')
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const [thumbnailType, setThumbnailType] = useState('animatedGIF')

  const [video, setVideo] = useState(false)
  const chunksRef = useRef<Blob[]>([])
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [stream, setStream] = useState<MediaStream | null>(null)
  const onBeforeUnload = (ev: any) => {
    ev.preventDefault()
    return (ev.returnValue =
      "There's some video recording in the progress. Are you sure you want to leave page?")
  }
  const onUnload = () => {
    deletePrevRecordedVideo()
  }
  let isEventAdded = false
  useEffect(() => {
    if (currentStep === 'recordVideo') {
      isEventAdded = false
      window.removeEventListener('beforeunload', onBeforeUnload)
      window.removeEventListener('unload', onUnload)
    } else {
      if (isEventAdded === false) {
        isEventAdded = true
        window.addEventListener('beforeunload', onBeforeUnload)
        window.addEventListener('unload', onUnload)
      }
    }
  }, [currentStep])
  const handleVideoClose = () => {
    setVideo(false)
  }
  const handleVideo = () => setVideo(true)
  const [isTrimming, setIsTrimming] = useState<Boolean>(false)
  const [taskQueue, setTaskQueue] = useState<string[]>([])
  const [videoLoadQueue, setVideoLoadQueue] = useState<boolean[]>([])
  const [isRecording, setIsRecording] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [duration, setDuration] = useState<number | null>(null)
  let [images, setImages] = useState(new Array(8).fill(''))
  const [sliderValue, setSliderValue] = useState<number[]>([])
  const [thumbnailValue, setThumbnailValue] = useState<number[]>([])
  const auth = getAuth()

  // const getSubscriptionDetails = async () => {
  //   const response = await getMySubscription(
  //     auth?.uid,
  //     () => {},
  //     () => {
  //       setSubscriptionDetails({})
  //     }
  //   )
  //   if (response) {
  //     setSubscriptionDetails(response.data)
  //   }
  // }

  // useEffect(() => {
  //   if (auth?.uid) {
  //     getSubscriptionDetails()
  //   }
  // }, [])

  const deletePrevRecordedVideo = async () => {
    let public_id = localStorage.getItem('public_id') || ''
    let formData = new FormData()
    let timestamp = new Date().getTime().toString()
    let signature = sha1(
      `public_id=${public_id}&timestamp=${timestamp}${
        process.env.REACT_APP_CLOUDINARY_TIMESTAMP || ''
      }`
    )
    console.log('signature:', signature)
    formData.append('public_id', public_id)
    formData.append('signature', signature)
    formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY || '')
    formData.append('timestamp', timestamp)
    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINAR_ID}/video/destroy`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    } catch (err) {}
  }
  const getModalTitle = () => {
    switch (currentStep) {
      case 'recordVideo':
        return 'Record video message'
      case 'recording':
        return 'RECORDING'
      case 'viewVideo':
        return 'View video message'
      case 'trimVideo':
        return 'Trim video message - MAX 60 seconds'
      case 'viewTrimmedVideo':
        return 'View video message'
      case 'thumbnail':
        return 'Select Thumbnail'
      case 'preview':
        return 'Preview'
    }
  }
  const getFooterBtns = () => {
    switch (currentStep) {
      case 'recordVideo':
        return (
          <>
            <Button onClick={handleClose} size='sm'>
              <i className='fa fa-angle-left'></i> Back
            </Button>
          </>
        )
      case 'viewVideo':
      case 'trimVideo':
      case 'viewTrimmedVideo':
        return (
          <div className='d-flex w-100 align-items-center'>
            <Button
              size='sm'
              onClick={async () => {
                // delete old cloudinary video

                let res = window.confirm('Are you sure you want to re-record video message?')
                if (res) {
                  deletePrevRecordedVideo()
                  clearPreviousVideoData()
                  setUrl('')
                  setIsRecording(false)
                  setIsVideoLoaded(false)
                  setCurrentStep('recordVideo')
                }
              }}
              variant='pink'
            >
              Re-record video
            </Button>
            <Button
              className='ms-auto'
              onClick={async () => {
                if (currentStep === 'trimVideo') {
                  setIsTrimming(true)
                  localStorage.setItem('video_start_offset', sliderValue[0].toString())
                  localStorage.setItem('video_end_offset', sliderValue[1].toString())
                  let url = localStorage.getItem('url')
                  // let splitedUrl = url?.split('/upload/a_hflip/')
                  let splitedUrl = url?.split('/upload/')
                  // let trimmedUrl =
                  //   splitedUrl?.[0] +
                  //   '/upload/a_hflip/' +
                  //   `eo_${sliderValue[1].toString()},so_${sliderValue[0].toString()}` +
                  //   '/' +
                  //   splitedUrl?.[1]
                  let trimmedUrl =
                    splitedUrl?.[0] +
                    '/upload/' +
                    `eo_${sliderValue[1].toString()},so_${sliderValue[0].toString()}` +
                    '/' +
                    splitedUrl?.[1]

                  uploadTrimVideo(
                    trimmedUrl,
                    allowCaptions,
                    (res: any) => {
                      let {public_id, secure_url, duration, bytes} = res.data
                      secure_url = secure_url.replace('.mkv', '.mp4')
                      let newTrimmedSplittedUrl = secure_url.split('/upload/')

                      if (allowCaptions == true) {
                        let {public_id: caption_public_id, secure_url: caption_url} =
                          res.captionsResult
                        setCaptionProp({caption_public_id, caption_url})
                      } else {
                        setCaptionProp(null)
                      }

                      let trimmedUrlWithWatermark = secure_url
                      if (isOnFreePlan()) {
                        trimmedUrlWithWatermark =
                          newTrimmedSplittedUrl?.[0] +
                          '/upload/' +
                          `eo_${sliderValue[1].toString()},so_${sliderValue[0].toString()}` +
                          `/l_${process.env.REACT_APP_WATERMARK}/fl_layer_apply/l_${process.env.REACT_APP_WATERMARK}/fl_layer_apply,y_35,x_30/l_${process.env.REACT_APP_WATERMARK}/fl_layer_apply,y_35,x_350/l_${process.env.REACT_APP_WATERMARK}/fl_layer_apply,y_350,x_30/l_${process.env.REACT_APP_WATERMARK}/fl_layer_apply,y_350,x_350/` +
                          newTrimmedSplittedUrl?.[1]
                      }

                      localStorage.setItem('trimmedUrl', secure_url)
                      localStorage.setItem('trimmedUrlWithWatermark', trimmedUrlWithWatermark)
                      localStorage.setItem('public_id', public_id)
                      // localStorage.setItem('url', secure_url)
                      localStorage.setItem('url', trimmedUrlWithWatermark)

                      localStorage.setItem('bytes', bytes)
                      console.log('SS', trimmedUrlWithWatermark)
                      setUrl(trimmedUrlWithWatermark)
                      setTimeout(() => {
                        setIsTrimming(false)
                        setCurrentTime(0)
                        // setCurrentStep(
                        //   currentStep === 'viewVideo'
                        //     ? 'trimVideo'
                        //     : currentStep === 'trimVideo'
                        //     ? 'viewTrimmedVideo'
                        //     : 'thumbnail'
                        // )
                        // setCurrentStep(
                        //   currentStep === 'viewVideo'
                        //     ? 'trimVideo'
                        //     : currentStep === 'trimVideo'
                        //     ? 'thumbnail'
                        //     : 'thumbnail'
                        // )

                        setCurrentStep('recordVideo')
                        submitStep()
                        setShowBtn(true)
                        handleClose()
                      }, 500)
                    },
                    (err: any) => {
                      setMessage(err || 'Unable to trim video')
                      setAlertType('error')
                      alertToggle()
                      setIsTrimming(false)
                    }
                  )
                } else {
                  console.log('currentStep:', currentStep)
                  if (currentStep === 'viewTrimmedVideo') {
                    let trimmedUrl = localStorage.getItem('trimmedUrl')
                    console.log('trimmedUrl:', trimmedUrl)
                    if (trimmedUrl) setUrl(trimmedUrl)
                  }

                  setCurrentTime(0)
                  setCurrentStep(
                    currentStep === 'viewVideo'
                      ? 'trimVideo'
                      : currentStep === 'trimVideo'
                      ? 'viewTrimmedVideo'
                      : 'thumbnail'
                  )
                  setTaskQueue((prevState) => {
                    let temp = JSON.parse(JSON.stringify(prevState))
                    temp.push('uploadingVideo')
                    return [...temp]
                  })

                  window.setTimeout(() => {
                    setTaskQueue((prevState) => {
                      let temp = JSON.parse(
                        JSON.stringify(prevState.filter((task) => task !== 'uploadingVideo'))
                      )
                      return [...temp]
                    })
                  }, 500)
                }
              }}
              size='sm'
            >
              {currentStep == 'trimVideo' ? (
                isTrimming ? (
                  'Saving'
                ) : (
                  'Save'
                )
              ) : (
                <>
                  Next <i className='fa fa-angle-right'></i>
                </>
              )}
            </Button>
          </div>
        )
      case 'thumbnail':
        return (
          <div className='d-flex w-100 align-items-center'>
            <Button onClick={() => setCurrentStep('viewTrimmedVideo')} size='sm'>
              <i className='fa fa-angle-left'></i> Back
            </Button>
            <Button
              className='ms-auto'
              onClick={() => {
                localStorage.setItem('thumbnail_start_offset', thumbnailValue[0].toString())
                localStorage.setItem('thumbnail_end_offset', thumbnailValue[1].toString())
                let url = localStorage.getItem('url')
                let video_start_offset = localStorage.getItem('video_start_offset')
                let video_end_offset = localStorage.getItem('video_end_offset')
                if (url && video_start_offset && video_end_offset) {
                  let splittedUrl = url.split('/upload/a_hflip/')
                  let animatedThumbnailUrl = `${splittedUrl?.[0]}/upload/a_hflip/eo_${video_end_offset},so_${video_start_offset}/eo_${thumbnailValue[1]},so_${thumbnailValue[0]}/${splittedUrl?.[1]}`
                  localStorage.setItem('animatedThumbnailUrl', animatedThumbnailUrl)
                  setCurrentStep('preview')
                }
              }}
              size='sm'
            >
              Save <i className='fa fa-angle-right'></i>
            </Button>
          </div>
        )
      case 'preview':
        return (
          <Button
            size='sm'
            className='ms-auto'
            onClick={() => {
              submitStep()
              setShowBtn(true)
              handleClose()
            }}
          >
            Close
          </Button>
        )
    }
  }

  useEffect(() => {
    if (requestData?.opportunity) {
      setIsCheckingOpportunityDetails(true)
      checkOpportunityDetails(
        {opportunity: requestData.opportunity},
        (res: any) => {
          if (res) {
            setTitle(res?.videoTitle)
          }

          setIsCheckingOpportunityDetails(false)
        },
        () => {
          setIsCheckingOpportunityDetails(false)
          setTitle('')
        }
      )
    }
  }, [requestData])

  return (
    <div className='w-100'>
      {isCheckingOpportunityDetails && <Loader />}

      <div className='pb-5 pb-lg-8  d-none d-xl-block'>
        <h2 className='fw-bold text-dark'>Record Video </h2>

        <div className='text-gray-400 fw-semibold fs-6'>Record a short video message</div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <div className='text-gray-400 fw-semibold mb-3 fs-6'>Hey {requestData?.recipientName}</div>
        <p className='text-gray-400'>
          You've been invited to record a video message up to 60 seconds long. Please ensure there’s
          minimal ambient noise when recording.
        </p>
      </div>
      <div className='mb-5'>
        <p className='text-pink fw-semibold mb-2'>Video requirements:</p>
        <p className='fw-semibold'> {requestData?.instructions}</p>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 fw-bold'>
          Email Subject <span className='text-danger'>*</span>
        </label>

        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='title'
          value={title}
          placeholder='Something memorable...'
          onChange={(e: {target: {value: string}}) => {
            const inputValue = e.target.value
            if (inputValue === '' || (inputValue.trim() !== '' && inputValue.trim().length <= 75)) {
              setTitle(inputValue)
            }
            // if (inputValue.length <= 75) {
            //   setTitle(inputValue)
            // }
          }}
        />
        <small>Max 75 characters</small>
      </div>
      <Modal
        show={show}
        onHide={() => {
          if (currentStep !== 'recordVideo') {
            let res = window.confirm(
              'You have some video recording in the progress. Are you sure you want to close dialog?'
            )
            if (res) {
              // localStorage.removeItem('animatedThumbnail')
              // localStorage.removeItem('url')
              // localStorage.removeItem('video_start_offset')
              // localStorage.removeItem('video_end_offset')
              // localStorage.removeItem('thumbnail_end_offset')
              // localStorage.removeItem('thumbnail_start_offset')
              // localStorage.removeItem('trimmedUrlWithWatermark')
              // localStorage.removeItem('trimmedUrl')
              deletePrevRecordedVideo()
              clearPreviousVideoData()
              handleClose()
            }
          } else {
            handleClose()
          }
        }}
        centered
        className='modal-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentStep === 'thumbnail' ? (
            <Row className='mb-5'>
              <Col sm={6}>
                <button
                  onClick={() => setThumbnailType('animatedGIF')}
                  className={`d-block bg-transparent rounded text-dark w-100 py-2 border-dotted  ${
                    thumbnailType === 'animatedGIF' ? 'border-danger' : 'border-dark'
                  }`}
                >
                  <small>Animated GIF</small>
                </button>
              </Col>
              <Col sm={6}>
                <button
                  onClick={() => setThumbnailType('staticImage')}
                  className={`d-block bg-transparent rounded text-dark w-100 py-2 border-dotted ${
                    thumbnailType === 'staticImage' ? 'border-danger' : 'border-dark'
                  }`}
                >
                  <small>Static image</small>
                </button>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <VideoRecorder
            videoLoadQueue={videoLoadQueue}
            setVideoLoadQueue={setVideoLoadQueue}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            thumbnailValue={thumbnailValue}
            setThumbnailValue={setThumbnailValue}
            duration={duration}
            setDuration={setDuration}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            taskQueue={taskQueue}
            setTaskQueue={setTaskQueue}
            isVideoLoaded={isVideoLoaded}
            setIsVideoLoaded={setIsVideoLoaded}
            url={url}
            setUrl={setUrl}
            images={images}
            thumbnailType={thumbnailType}
            isOnFreePlan={isOnFreePlan}
            isTrimming={isTrimming}
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          {!(taskQueue.includes('uploadingVideo') || taskQueue.includes('loadingTrimmedVideo')) &&
          videoLoadQueue.length == 0
            ? getFooterBtns()
            : ''}
        </Modal.Footer>{' '}
      </Modal>
      <Modal show={video} onHide={handleVideoClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Record Video Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <KTSVG path='/media/svg/landscape-modals/exclamation.svg' className=' svg-icon-5x' />

            <p className='my-5'>
              Please rotate your screen to landscape to record your video message!
            </p>
            <SVG src='/media/svg/landscape-modals/phone_spin.svg' className='mh-250px' />
          </div>
        </Modal.Body>
      </Modal>
      {!isOnFreePlan() && (
        <AllowCaptionsSection
          allowCaptions={allowCaptions}
          handleCaptionChange={handleCaptionChange}
        />
      )}
      <Button
        variant='primary'
        disabled={!title}
        onClick={
          isMobileScreen
            ? () => {
                deletePrevRecordedVideo()
                clearPreviousVideoData()
                setCurrentStep('recordVideo')
                setUrl('')
                setIsRecording(false)
                setIsVideoLoaded(false)
                // handleVideo()
                handleShow()
              }
            : () => {
                deletePrevRecordedVideo()
                clearPreviousVideoData()

                setCurrentStep('recordVideo')
                setUrl('')
                setIsRecording(false)
                setIsVideoLoaded(false)
                handleShow()
              }
        }
        className='rounded-1'
      >
        Record Video Message <i className='fa-regular fa-circle-play mb-1'></i>
      </Button>

      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </div>
  )
}

export {Step2}
