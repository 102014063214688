import React, {ReactNode} from 'react'
import './Loader.css'

interface FullPageOverlayProps {
  children: ReactNode
}

export const FullPageOverlay = ({children}: FullPageOverlayProps) => {
  return (
    <div
      // style={{
      //   position: 'fixed',
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100vh',
      //   backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the overlay color and opacity as needed
      //   zIndex: 9999, // Ensure it's above other elements
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      // }}
      className='full-page-overlay'
    >
      {children}
    </div>
  )
}

const Loader = () => {
  return (
    <FullPageOverlay>
      <div>
        <svg
          style={{margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto'}}
          width='200px'
          height='200px'
          viewBox='0 0 100 100'
          preserveAspectRatio='xMidYMid'
          xmlns='http://www.w3.org/2000/svg' // Add xmlns attribute here
        >
          <circle cx='30' cy='50' fill='#ff00ae' r='20'>
            <animate
              attributeName='cx'
              repeatCount='indefinite'
              dur='1s'
              keyTimes='0;0.5;1'
              values='30;70;30'
              begin='-0.5s'
            />
          </circle>
          <circle cx='70' cy='50' fill='#00f2ba' r='20'>
            <animate
              attributeName='cx'
              repeatCount='indefinite'
              dur='1s'
              keyTimes='0;0.5;1'
              values='30;70;30'
              begin='0s'
            />
          </circle>
          <circle cx='30' cy='50' fill='#ff00ae' r='20'>
            <animate
              attributeName='cx'
              repeatCount='indefinite'
              dur='1s'
              keyTimes='0;0.5;1'
              values='30;70;30'
              begin='-0.5s'
            />
            <animate
              attributeName='fill-opacity'
              values='0;0;1;1'
              calcMode='discrete'
              keyTimes='0;0.499;0.5;1'
              dur='1s'
              repeatCount='indefinite'
            />
          </circle>
        </svg>

        {/* <div className='lds-dual-ring'></div> */}
      </div>
    </FullPageOverlay>
  )
}

export default Loader
