import axios from 'axios'
const baseURL = 'https://us-central1-greetrz.cloudfunctions.net/app'

export default axios.create({
  baseURL,
  headers: {
    'Cache-Control': 'no-cache',
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data',
    // Authorization:
    //   "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
  },
})
