import BillingRepository from './BillingRepository'
import UserRespository from './UserRespository'
import VideoRepository from './VideoRepository'
const repositories: any = {
  user: UserRespository,
  video: VideoRepository,
  billing:BillingRepository
}

export const RepositoryFactory = {
  get: (name: any) => repositories[name],
}
