import {Outlet} from 'react-router-dom'
import {HeaderWrapperRecordVideo} from '../../../_metronic/layout/components/header/HeaderWrapperRecordVideo'
import clsx from 'clsx'
import {useLayout} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const RecordVideoLayout = () => {
  const {classes} = useLayout()
  return (
    <div className='page d-flex flex-row flex-column-fluid'>
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div
          style={{
            height: '260px',
            width:'100%',
            backgroundSize:'cover',
            backgroundImage: `url(${toAbsoluteUrl('/media/patterns/headernew-bg.PNG')})`,
          }}
        >
          <HeaderWrapperRecordVideo />
        </div>
        <div
          style={{marginTop: '-120px'}}
          id='kt_content'
          className='content d-flex flex-column flex-column-fluid'
        >
          <div
            className={clsx(
              'd-flex flex-column-fluid align-items-start',
              classes.contentContainer.join(' ')
            )}
            id='kt_post'
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecordVideoLayout
