import {FC} from 'react'

type Props = {
  viewed: any
}

const OpportunityViewCell: FC<Props> = ({viewed}) => (
  <>
    <div className=''>{viewed?.opportunityStats.videosViewed}</div>
  </>
)

export {OpportunityViewCell}
