import {FC} from 'react'

type Props = {
  notOpened: any
}

const OpportunityNotOpenedCell: FC<Props> = ({notOpened}) => (
  <>
    <div className=''>{notOpened?.opportunityStats.videosNotOpened}</div>
  </>
)

export {OpportunityNotOpenedCell}
