export const filterRecentVideos = (videos: any, filterValue: any) => {
    // if (activeTab === 'sendVideo') {
      switch (filterValue) {
        case 'opened':
          return videos.filter((item: any) => item.isOpened)
        case 'not-opened':
          return videos.filter((item: any) => !item.isOpened)
        case 'viewed':
          return videos.filter((item: any) => item.fullyWatched)
        case 'interested':
          return videos.filter(
            (item: any) =>
              item.recipientReaction !== '' && item.recipientReaction !== 'not-interested'
          )
        case 'not-interested':
          return videos.filter((item: any) => item.recipientReaction === 'not-interested')
        default:
          return videos
      }
    // } else if (activeTab === 'requestVideo') {
    //   switch (filterValue) {
    //     case 'opened':
    //       return videos.filter((item: any) => item.isOpened)
    //     case 'not-opened':
    //       return videos.filter((item: any) => !item.isOpened)
    //     case 'viewed':
    //       return videos.filter((item: any) => item.fullyWatched)
    //     default:
    //       return videos
    //   }
    // }
  }