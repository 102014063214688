import axios from 'axios'
import firebase from 'firebase/app'
import {ID} from '../../../../../../_metronic/helpers'
import {RepositoryFactory} from '../../../../../repository/RepositoryFactory'
import {getAuth} from '../../../../auth'
import {User} from './_models'
import {getPricingQuota} from '../../../../../utils/getPricingQuota'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
let UserApi = RepositoryFactory.get('user')

// ===========GET USERS============
const getUsers: any = async (query: string) => {
  try {
    const currentUser = firebase.auth().currentUser

    let details = await firebase.firestore().collection('users').doc(currentUser?.uid).get()
    console.log(details.data())
    let {role, createdById}: any = details.data()
    let id: any = currentUser?.uid
    if (role != 'super-admin') {
      id = createdById
    }
    console.log('CreatedBy', id)
    const usersSnapshot = await firebase
      .firestore()
      .collection('users')
      .where('createdById', '==', id)
      // .orderBy('createdAt', 'desc')
      .get()

    const usersData = usersSnapshot.docs
      .filter(
        (doc) =>
          (!doc.data().isDeleted || doc.data().isDeleted !== true) && doc.id !== currentUser?.uid
      )
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

    // Retrieve login details for each user
    const usersWithLoginDetails = await Promise.all(
      usersData.map(async (user) => {
        const loginHistory = await getLastLoginDetails(user.id)
        return {
          ...user,
          lastLogin: loginHistory ? loginHistory.loginTime : null,
        }
      })
    )

    // HANDLE FILTRATION AND SEARCH
    let filteredData = usersWithLoginDetails

    if (query.includes('&filter_last_login=')) {
      let minutes: number = Number(query.split('&filter_last_login=')[1])
      const totalMinutesAgo = firebase.firestore.Timestamp.now().seconds - minutes * 60

      filteredData = filteredData.filter((data: any) => {
        const lastLoginTimeInSeconds = data?.lastLoginTime?.seconds
        return lastLoginTimeInSeconds >= totalMinutesAgo
      })
    }

    filteredData.sort((a: any, b: any) => b.createdAt - a.createdAt)

    if (query.includes('&search=') && !query.includes('&filter')) {
      const searchTerm = query.split('&search=')[1].toLowerCase()
      filteredData = filteredData.filter(
        (data: any) =>
          data.firstName.toLowerCase().includes(searchTerm) ||
          data.lastName.toLowerCase().includes(searchTerm)
      )
    }
    if (query.includes('&search=') && query.includes('&filter')) {
      const searchTerm = query.split('&search=')[1].toLowerCase()
      const searchTerm2 = searchTerm.split('&filter')[0].toLowerCase()
      filteredData = filteredData.filter(
        (data: any) =>
          data.firstName.toLowerCase().includes(searchTerm2) ||
          data.lastName.toLowerCase().includes(searchTerm2)
      )
    }

    if (query.includes('&filter_role=') && !query.includes('&filter_last_login=')) {
      const filterTerm = query.split('&filter_role=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) => data.role.toLowerCase() === filterTerm)
    }

    if (query.includes('&filter_role=') && query.includes('&filter_last_login=')) {
      if (query.split('&filter_role=')[1].length > 0) {
        const filterTerm = query.split('&filter_role=')[1].toLowerCase()
        const filterTerm2 = filterTerm.split('&filter_last_login=')[0].toLowerCase()
        filteredData = filteredData.filter((data: any) => data.role.toLowerCase() === filterTerm2)
      }
    }

    // HANDLE PAGINATION
    const page = parseInt(query.split('page=')[1].split('&')[0])
    const itemsPerPage = parseInt(query.split('items_per_page=')[1])
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const paginatedData = filteredData.slice(startIndex, endIndex)

    // GENERATING PAGINATION LINKS ARRAY DYNAMICALLY
    const links = []
    if (totalPages > 0) {
      // PREVIOUS BUTTON
      links.push({
        url: page > 1 ? `/?page=${page - 1}` : null,
        label: '&laquo; Previous',
        active: false,
        page: page > 1 ? page - 1 : null,
      })

      // PAGE NUMBERS
      for (let i = 1; i <= totalPages; i++) {
        const url = `/?page=${i}`
        const label = i.toString()
        const active = i === page
        links.push({url, label, active, page: i})
      }

      // NEXT BUTTON
      links.push({
        url: page < totalPages ? `/?page=${page + 1}` : null,
        label: 'Next &raquo;',
        active: false,
        page: page < totalPages ? page + 1 : null,
      })
    }

    return {
      data: paginatedData,
      payload: {
        pagination: {
          first_page_url: `/?page=1`,
          from: startIndex + 1,
          items_per_page: itemsPerPage,
          last_page: totalPages,
          links,
          next_page_url: page < totalPages ? `/?page=${page + 1}` : null,
          page,
          prev_page_url: page > 1 ? `/?page=${page - 1}` : null,
          to: endIndex,
          total: filteredData.length,
        },
      },
    }
  } catch (error) {
    console.error('Error getting contacts: ', error)
  }
}

// ===========GET SINGLE USER============
const getUserById: any = async (id: ID) => {
  try {
    const userSnapshot = await firebase.firestore().collection('users').doc(id).get()

    if (!userSnapshot.exists) {
      throw new Error('Contact not found')
    }

    const userData = {
      id: userSnapshot.id,
      ...userSnapshot.data(),
    }
    return userData
  } catch (error) {
    console.error('Error getting contact: ', error)
  }
}

// ===========CREATE USER============
const createUser = async (
  user: User,
  priceID: string = '',
  onSuccess = () => {},
  onError = (err: any) => {}
) => {
  try {
    let auth: any = getAuth()
    let userDetails: any = null
    let priceId = null

    const ownerMail = auth?.email
    const userMail = user?.email

    if (!ownerMail || !userMail) {
      onError('Email addresses are missing')
      return
    }

    const ownerDomainName = ownerMail.substring(ownerMail.lastIndexOf('@') + 1)
    const userDomainName = userMail.substring(userMail.lastIndexOf('@') + 1)

    if (ownerDomainName != userDomainName) {
      onError('New users must have the same @domain.com as the owner')
      return
    }

    if (auth.role !== 'super-admin') {
      let userData = await getUserById(auth.createdById)
      userDetails = userData
      priceId = userData.priceId
    } else {
      userDetails = auth
      priceId = auth.priceId
    }

    let features: any = await getPricingQuota(priceId || '')
    console.log('features:', features)
    if (!userDetails?.allowFreeAccess && !features) {
      // alert('You have used your available quota. Upgrade you plan to add more users.')
      onError('You have used your available quota. Upgrade you plan to add more users.')
      return
    }
    if (!auth) {
      onError('You are not logged in')

      return
    }

    if (
      !userDetails?.allowFreeAccess &&
      typeof features.users == 'string' &&
      !features.users.toLowerCase().includes('unlimited')
    ) {
      // alert('You have used your available quota. Upgrade you plan to add more users.')
      onError('You have used your available quota. Upgrade you plan to add more users.')
      return
    } else if (
      typeof features.users != 'string' &&
      !userDetails?.allowFreeAccess &&
      auth.usage.users >= features.users
    ) {
      // alert('You have used your available quota. Upgrade you plan to add more users.')
      onError('You have used your available quota. Upgrade you plan to add more users.')
      return
    }
    const currentUser = firebase.auth().currentUser

    if (!currentUser) {
      throw new Error('No authenticated user found')
    }
    const response = await UserApi.createUser({...user, createdById: currentUser.uid})

    await firebase
      .firestore()
      .collection('users')
      .doc(response?.data?.data?.uid)
      .set({
        firstName: user.firstName,
        lastName: user.lastName,
        // name: user.name,
        email: user.email,
        phone: user.phone,
        role: user.role,
        status: 'active',
        accountAccess: user.accountAccess,
        createdAt: firebase.firestore.Timestamp.now(),
        createdById: currentUser.uid,
        notificationsSettings: {
          essential: true,
          weeklyReports: false,
          monthlyReports: false,
          openTracking: false,
        },
        platform: 'email',
        unreadNotifications: 0,
      })
    await firebase
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .update({
        'usage.users': firebase.firestore.FieldValue.increment(1),
      })
    onSuccess()
  } catch (error: any) {
    onError(error?.response?.data?.message || error?.message)
    // alert(error?.response.data.message)
    console.log('Error adding user: ', error)
  }
}

// ============UPDATE USER==============
const updateUser = async (
  user: User,
  onSuccess = () => {},
  onError = (err: any) => {}
): Promise<string | void> => {
  try {
    let auth: any = getAuth()
    const ownerMail = auth?.email
    const userMail = user?.email

    if (!ownerMail || !userMail) {
      onError('Email addresses are missing')
      return
    }

    const ownerDomainName = ownerMail.substring(ownerMail.lastIndexOf('@') + 1)
    const userDomainName = userMail.substring(userMail.lastIndexOf('@') + 1)

    if (ownerDomainName != userDomainName) {
      onError('New users must have the same @domain.com as the owner')
      return
    }
    const response = await UserApi.updateUser({userId: user.id, email: user.email})

    console.log(response)
    const userRef = firebase.firestore().collection('users').doc(user.id)

    await userRef.update({
      ...user,
      updatedAt: firebase.firestore.Timestamp.now(),
    })
    onSuccess()
  } catch (error: any) {
    // alert(error?.response?.data?.error)
    // console.log('Error updating contact: ', error)
    onError(error?.response?.data?.error || error?.message || 'Unable to update user')
  }
}

// ==========DELETE USER============
// const deleteUser = (userId: ID): Promise<void> => {
//   return axios.delete(`${USER_URL}/${userId}`).then(() => {})
// }
const deleteUser = async (id: ID) => {
  try {
    let auth: any = getAuth()

    const response = await UserApi.deleteUser({id})
    if (response.status === 200) {
      try {
        if (auth) {
          let docID = auth.role === 'super-admin' ? auth?.uid : auth.createdById
          await firebase
            .firestore()
            .collection('users')
            .doc(docID)
            .update({
              'usage.users': firebase.firestore.FieldValue.increment(-1),
            })
        }
        let recordedVideosDocs = await firebase
          .firestore()
          .collection('recordedVideos')
          .where('sendById', '==', id)
          .get()
        let opportunityDocs = await firebase
          .firestore()
          .collection('opportunity')
          .where('createdById', '==', id)
          .get()
        let requestVideoDocs = await firebase
          .firestore()
          .collection('requestVideo')
          .where('requestedById', '==', id)
          .get()
        console.log(
          'recordedVideosDocs:',
          recordedVideosDocs.docs.map((doc) => doc.id)
        )
        console.log(
          'opportunityDocs:',
          opportunityDocs.docs.map((doc) => doc.id)
        )
        console.log(
          'requestVideoDocs:',
          requestVideoDocs.docs.map((doc) => doc.id)
        )
        let uid = auth.role === 'super-admin' ? auth.uid : auth.createdById
        await Promise.all([
          ...recordedVideosDocs.docs.map((doc) =>
            firebase.firestore().collection('recordedVideos').doc(doc.id).update({
              sendById: uid,
            })
          ),
          ...opportunityDocs.docs.map((doc) =>
            firebase.firestore().collection('opportunity').doc(doc.id).update({
              createdById: uid,
            })
          ),
          ...requestVideoDocs.docs.map((doc) =>
            firebase.firestore().collection('requestedById').doc(doc.id).update({
              requestedById: uid,
            })
          ),
        ])
        const userRef = firebase.firestore().collection('users').doc(id)
        await userRef.set(
          {
            isDeleted: true,
            updatedAt: firebase.firestore.Timestamp.now(),
          },
          {merge: true}
        )

        console.log('Contact updated successfully')
      } catch (error) {
        console.error('Error updating contact: ', error)
      }
    }
  } catch (error) {
    console.log('There was an error:', error)
  }
}

// =============Resend email Verification mail User==============
const resendUserEmail = async (
  payload: any,
  onSuccess = (res: any) => {},
  onError = (error: string) => {}
) => {
  try {
    const {data} = await UserApi.resendUserVerificationEmail(payload)
    if (data.success == true) {
      onSuccess(data?.message)
      return data
    }
  } catch (error: any) {
    console.log(error)
    onError(error?.response?.data?.error || 'Oops! something went wrong, unable to resend email')
  }
}
// ============= Verification account==============

const verifyUser = async (
  id: ID,
  onSuccess = (res: any) => {},
  onError = (error: string) => {}
) => {
  try {
    const {data} = await UserApi.verifyUserStatus(id)
    if (data.success == true) {
      onSuccess(data?.verified)
      return data
    }
  } catch (error: any) {
    console.log(error)
    onError(error?.response?.data?.error || 'Oops! something went wrong, unable to resend email')
  }
}

// =============Disable User==============
const disableUser = async (id: ID, onSuccess = () => {}, onError = (error: string) => {}) => {
  try {
    const response = await UserApi.disableUser({id})
    onSuccess()
    return response
  } catch (error: any) {
    console.log('There was an error:', error)
    onError(
      error?.response?.data?.error || 'Oops! something went wrong unable to deactivate account '
    )
  }
}
// =============Enable User==============
const enableUser = async (id: ID, onSuccess = () => {}, onError = (error: any) => {}) => {
  try {
    const response = await UserApi.enableUser({id})

    console.log(response)
    onSuccess()
    return response
  } catch (error) {
    onError(error)
    console.log('There was an error:', error)
  }
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getLastLoginDetails = async (userId: string) => {
  try {
    const loginHistoryRef = firebase
      .firestore()
      .collection('loginHistory')
      .where('userId', '==', userId)

    const querySnapshot = await loginHistoryRef.get()

    if (!querySnapshot.empty) {
      const lastLoginDoc = querySnapshot.docs[0]
      const lastLoginData = lastLoginDoc.data()

      const lastLoginTime = lastLoginData.loginTime.toDate()
      const options = {
        // weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        // timeZoneName: 'short',
      }
      const lastLoginTimeString = lastLoginTime.toLocaleString('en-US', options)
      return {
        userId: lastLoginData.userId,
        loginTime: lastLoginTimeString,
      }
    } else {
      console.log('No login history found for the user')
      return null
    }
  } catch (error) {
    console.error('Error retrieving last login details: ', error)
    return null
  }
}

const sendQuery = async (data: any, onSuccess = () => {}, onError = (err: any) => {}) => {
  try {
    const res = await UserApi.sendQuery(data)
    onSuccess()
  } catch (error: any) {
    console.log(error)
    // alert(error?.response?.data?.message || 'Something went wrong')
    onError(error?.response?.data?.error)
  }
}

// =============Enable User==============
const unsubscribeEmailNotification = async (
  email: any,
  onSuccess = (res: any) => {},
  onError = (error: any) => {}
) => {
  try {
    const response = await UserApi.unsubscribeEmail(email)

    console.log(response)
    onSuccess(response)
    return response
  } catch (error) {
    onError('error')
    console.log('There was an error:', error)
    return error
  }
}

export {
  createUser,
  deleteSelectedUsers,
  resendUserEmail,
  verifyUser,
  deleteUser,
  disableUser,
  enableUser,
  getUserById,
  getUsers,
  sendQuery,
  updateUser,
  unsubscribeEmailNotification,
}
