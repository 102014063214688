import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Step2} from './urlSteps/Step2'
import {Step5} from './urlSteps/Step5'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {createAccountSchemas, isValidEmail, isValidName} from '../CreateAccountWizardHelper'
import {Button, Modal} from 'react-bootstrap'
import {Step6} from './urlSteps/Step6'
import {useNavigate, useParams} from 'react-router-dom'
import {
  getRequestedDataForRecordVideo,
  handleOpportunityStats,
  sendEmail,
  sendVideoAgainstRequest,
} from '../../../../oportunities/Components/opportunity-list/core/_requests'
import Loader, {FullPageOverlay} from '../../../../accounts/components/Loading/Loader'
import {getMySubscription} from '../../../../accounts/core/_requests'
import {
  checkEmailSubscription,
  getUserDetails,
} from '../../../notifications/components/core/_requests'
import {clearPreviousVideoData, deletePrevRecordedVideo} from '../../deleteCloudinaryVideo'
import MessageAlert from '../../../global/MessageAlert'
import EmailAnimation from '../../../global/EmailAnimation'
// import {Helmet} from 'react-helmet'

const RecordVideoForSharedUrl = () => {
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [requestData, setRequestData] = useState<any>()
  const params: any = useParams()
  const [userDetails, setUserDetails] = useState({name: '', email: ''})
  const [userDetailsError, setUserDetailsError] = useState({name: false, email: false})
  const [url, setUrl] = useState('')
  const [title, setTitle] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({})
  const [creatorDetails, setCreatorDetails] = useState<any>({})
  const [captions, setCaptionProp] = useState(null)
  console.log(captions, 'captionProp')

  const handleChange = (e: any) => {
    let {name, value} = e.target
    setUserDetails((prev) => ({...prev, [name]: value}))
  }

  useEffect(() => {
    if (params.id) {
      const fetchRequestForVideo = async () => {
        setIsLoading(true)
        const response: any = await getRequestedDataForRecordVideo(params.id)
        console.log(response)
        setRequestData(response)
        setIsLoading(false)
      }
      fetchRequestForVideo()
    }
  }, [])
  const getSubscriptionDetails = async () => {
    const response = await getMySubscription(
      requestData?.senderDetails?.id,
      () => {},
      () => {
        setSubscriptionDetails({})
      }
    )
    if (response) {
      setSubscriptionDetails(response.data)
    }
  }
  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }

  // Alert Ends
  const getCreatorData = (id: any) => {
    getUserDetails(
      id,
      (res) => {
        setCreatorDetails(res)
      },
      (err: any) => {
        setMessage(err || 'User not found')
        setAlertType('error')
        alertToggle()
      }
    )
  }

  useEffect(() => {
    if (requestData?.senderDetails?.id) {
      if (requestData?.senderDetails?.role == 'super-admin') {
        getCreatorData(requestData?.senderDetails?.id)
      } else {
        getCreatorData(requestData?.senderDetails?.createdById)
      }
      getSubscriptionDetails()
    }
  }, [requestData])
  console.log('requestData==>', requestData)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }
  const [totalSteps, setTotalSteps] = useState(3)

  const submitStep = async () => {
    if (!stepper.current) {
      return
    }
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])
    if (stepper.current.currentStepIndex === 1 || stepper.current.currentStepIndex === undefined) {
      if (!isValidName(userDetails.name) && !isValidEmail(userDetails.email)) {
        setUserDetailsError({name: true, email: true})
        return
      } else if (!isValidName(userDetails.name) && isValidEmail(userDetails.email)) {
        setUserDetailsError((prev) => ({...prev, name: true}))
        return
      } else if (isValidName(userDetails.name) && !isValidEmail(userDetails.email)) {
        setUserDetailsError((prev) => ({...prev, email: true}))
        return
      } else {
        stepper.current.goto(1)
      }
    }
    if (stepper.current.currentStepIndex !== totalSteps) {
      stepper.current.goNext()
    } else {
      setIsSubmitting(true)
      let payload: any = {
        recipientEmail: userDetails.email,
        senderId: requestData?.requestedById,
        emailFor: 'confirmation-video',
        variables: {
          subject: title,
          recipientName: userDetails.name,
          link: url,
          // link: localStorage.getItem('trimmedUrlWithWatermark'),
        },
      }
      let videoSize: any = localStorage.getItem('bytes') || 0
      const gigabytes: any = videoSize / 1073741824
      let data = {
        requestId: requestData?.id,
        opportunity: requestData.opportunity,
        videoSenderName: userDetails.name,
        videoSenderEmail: userDetails.email,
        videoTitle: title,
        videoUrl: url,
        videoSize: gigabytes,
        captions,
        // videoUrl: localStorage.getItem('trimmedUrlWithWatermark'),
        // thumbnail: localStorage.getItem('animatedThumbnailUrl'),
      }
      checkEmailSubscription(userDetails?.email, async (res) => {
        if (res) {
          await sendVideoAgainstRequest(
            data,
            creatorDetails,
            async (id) => {
              const {variables, ...restPayload} = payload
              const updatedPayload = {
                ...restPayload,
                variables: {
                  ...variables,
                  link: `https://app.greetrs.com/auth/view-video/${id}`,
                },
              }
              await sendEmail(updatedPayload)
              handleOpportunityStats(requestData.opportunity, 'videosNotOpened')
              handleShow()
              setIsSubmitting(false)
              clearPreviousVideoData()
              // localStorage.removeItem('animatedThumbnail')
              // localStorage.removeItem('animatedThumbnailUrl')
              // localStorage.removeItem('url')
              // localStorage.removeItem('video_start_offset')
              // localStorage.removeItem('video_end_offset')
              // localStorage.removeItem('thumbnail_end_offset')
              // localStorage.removeItem('thumbnail_start_offset')
              // localStorage.removeItem('trimmedUrlWithWatermark')
              // localStorage.removeItem('trimmedUrl')
            },
            (err: any) => {
              setMessage(err || 'Error in sending video')
              if (err & err?.includes('available quota')) {
                setAlertType('warning')
              } else {
                setAlertType('error')
              }
              alertToggle()
              setIsSubmitting(false)
            }
          )
        } else {
          setMessage(
            "This user has unsubscribed from greetrs and won't get your email kindly share a link with them"
          )
          setAlertType('error')
          alertToggle()
          setIsSubmitting(false)
        }
      })
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [videoShow, setVideoShow] = useState(false)
  const handleVideoClose = () => setVideoShow(false)
  const handleVideoShow = () => setVideoShow(true)
  useEffect(() => {
    const handleBeforeUnload = () => {
      let public_id = localStorage.getItem('public_id')

      if (public_id) {
        deletePrevRecordedVideo()
        setTimeout(() => {
          clearPreviousVideoData()
        }, 500)
      }
    }

    return () => {
      handleBeforeUnload()
    }
  }, [])
  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10 w-100' id='kt_profile_details_view'>
        <div className='my-20 d-flex justify-content-center'>
          <Loader />
        </div>
      </div>
    )
  }

  console.log('...', stepper.current?.currentStepIndex)
  return (
    <>
      {/* <Helmet>
        <title>Greetrs</title>
        <meta name='description' content='Greetrs' />
        <meta property='og:title' content='Greetrs' />
        <meta
          property='og:description'
          content='Hey I would like to request that you record and submit a short (max:60 seconds) video message.'
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/greetrz.appspot.com/o/photo-check.jpg?alt=media&token=d2ce3b79-0346-40d2-ad65-4991cc4a5a05'
        />
      </Helmet> */}

      {requestData && requestData.status === 'recorded' ? (
        <div className='card w-100' id='kt_profile_details_view'>
          <div className='my-20 d-flex justify-content-center'>
            <h2 className='text-center px-2'>
              A video has already been recorded for this opportunity. Only one video can be recorded
              per request.
            </h2>
          </div>
        </div>
      ) : (
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          {/* begin::Aside*/}
          <div className='card d-xl-flex d-none justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
            {/* begin::Wrapper*/}
            <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
              {/* begin::Nav*/}
              <div className='stepper-nav d-none d-xl-block'>
                {/* begin::Step 2*/}
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className={`stepper-icon w-40px h-40px`}>
                      {/* <i className='stepper-check fas fa-check '></i> */}
                      <span className='text-danger stepper-check'>1</span>

                      <span className={`stepper-number `}>1</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Your Name</h3>
                      <div className='stepper-desc fw-semibold text-nowrap'>
                        Name and Email Address
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>

                <div className='stepper-item ' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className={`stepper-icon w-40px h-40px`}>
                      {/* <i className='stepper-check fas fa-check '></i> */}
                      <span className='text-danger stepper-check'>2</span>

                      <span className={`stepper-number `}>2</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Record Video</h3>
                      <div className='stepper-desc fw-semibold text-nowrap'>
                        Record and trim your video
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div
                      className={`stepper-icon w-40px h-40px ${
                        stepper.current?.currentStepIndex === 3
                          ? 'bg-pink'
                          : 'bg-light-pink text-white'
                      }`}
                    >
                      {/* <i className='stepper-check fas fa-check '></i> */}
                      <span className='text-danger stepper-check'>3</span>

                      <span
                        className={`stepper-number ${
                          stepper.current?.currentStepIndex === 3 ? '' : 'text-danger'
                        }`}
                      >
                        3
                      </span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Submit</h3>
                      <div className='stepper-desc fw-semibold text-nowrap'>
                        Submit Your Video by ahsan{' '}
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  {/* <div className='stepper-line h-40px'></div> */}
                  {/* end::Line*/}
                </div>
              </div>
            </div>
          </div>

          {/* Modal for re-record video  */}
          <Modal show={videoShow} centered>
            <Modal.Header closeButton>
              <Modal.Title>Trim Video Max 60 Seconds</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                width='100%'
                height='315'
                src='https://www.youtube.com/embed/EMTZUfIbiBk'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              ></iframe>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
              <Button variant='primary' onClick={handleVideoClose} className='rounded-1'>
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for success video message  */}
          <Modal show={show} centered>
            <Modal.Body>
              <div className='modal-body justify-content-center'>
                <div className='d-flex justify-content-center mb-5'>
                  {/* <i className='fa-regular fa-circle-check fa-5x text-success rounded-pill'></i> */}
                  <img
                    src='/media/svg/landscape-modals/tick.svg'
                    width={65}
                    height={65}
                    alt='img'
                  />
                </div>
                <p className='text-center my-5'>Your Video has been submitted Successfully</p>
                <p className='text-center my-5'>Thank you for using greetrs</p>
              </div>
            </Modal.Body>
            <div className='m-4 d-flex justify-content-center'>
              <button
                onClick={() => {
                  handleClose()
                  navigate('/auth/login')
                }}
                className='btn btn-primary'
              >
                Close
              </button>
            </div>
          </Modal>

          <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
            <form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100 '>
                  <div className='stepper-item current d-block d-xl-none mb-5'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className={`stepper-icon w-40px h-40px bg-pink`}>
                        {/* <i className='stepper-check fas fa-check '></i> */}
                        <span className='text-danger stepper-check'>1</span>

                        <span className={`stepper-number `}>1</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Your Name</h3>
                        <div className='stepper-desc fw-semibold text-nowrap'>
                          Name and Email Address
                        </div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    {/* <div className='stepper-line h-40px'></div> */}
                    {/* end::Line*/}
                  </div>
                  <Step6
                    userDetails={userDetails}
                    handleChange={handleChange}
                    userDetailsError={userDetailsError}
                    setUserDetailsError={setUserDetailsError}
                  />
                </div>
              </div>
              <div data-kt-stepper-element='content'>
                <div>
                  <div className='stepper-item d-block d-xl-none mb-5  current'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div
                        className={`stepper-icon w-40px h-40px 
                     bg-pink
                        
                        `}
                      >
                        {/* <i className='stepper-check fas fa-check '></i> */}
                        <span className='text-danger stepper-check'>2</span>

                        <span className={`stepper-number `}>2</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Record Video</h3>
                        <div className='stepper-desc fw-semibold text-nowrap'>
                          Record and trim your video
                        </div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    {/* <div className='stepper-line h-40px'></div> */}
                    {/* end::Line*/}
                  </div>
                  <Step2
                    title={title}
                    setTitle={setTitle}
                    url={url}
                    setUrl={setUrl}
                    setCaptionProp={setCaptionProp}
                    submitStep={submitStep}
                    requestData={requestData}
                    userDetails={userDetails}
                    setShowBtn={setShowBtn}
                    subscriptionDetails={subscriptionDetails}
                    creatorDetails={creatorDetails}
                  />
                </div>
              </div>
              <div data-kt-stepper-element='content'>
                <div>
                  <div className='stepper-item d-block d-xl-none mb-5 current'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div
                        className={` stepper-icon w-40px h-40px 
                          bg-pink`}
                      >
                        {/* <i className='stepper-check fas fa-check '></i> */}
                        <span className='text-danger stepper-check'>3</span>

                        <span
                          className={`stepper-number ${
                            stepper.current?.currentStepIndex === 3 ? '' : 'text-danger'
                          }`}
                        >
                          3
                        </span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Submit</h3>
                        <div className='stepper-desc fw-semibold text-nowrap'>
                          Submit Your Video
                        </div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    {/* <div className='stepper-line h-40px'></div> */}
                    {/* end::Line*/}
                  </div>
                  <Step5 url={url} />
                </div>
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className=' d-lg-flex'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3 rounded-1'
                    data-kt-stepper-action='previous'
                    disabled={isSubmitting}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>
                {isSubmitting && (
                  <FullPageOverlay>
                    <EmailAnimation />
                  </FullPageOverlay>
                )}

                {(stepper.current?.currentStepIndex === 1 ||
                  // stepper.current?.currentStepIndex === 2 ||
                  stepper.current?.currentStepIndex === totalSteps) && (
                  <div>
                    <button
                      type='button'
                      onClick={submitStep}
                      disabled={isSubmitting}
                      className='btn btn-lg btn-primary me-3 my-5'
                    >
                      <span className='indicator-label'>
                        {stepper.current?.currentStepIndex == totalSteps
                          ? isSubmitting
                            ? 'Submitting'
                            : 'Submit'
                          : 'Continue'}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </>
  )
}

export {RecordVideoForSharedUrl}
