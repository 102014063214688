// import {createRoot} from 'react-dom/client'
import {render} from 'react-dom'
// Axios
import axios from 'axios'
// import {Chart, registerables} from 'chart.js'
// import {Chart} from 'chart.js/auto'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import * as Sentry from '@sentry/react'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {ThemeModeProvider} from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
// Chart.register(...registerables)

// Chart.register(require('chart.js/plugins/annotation'))

Sentry.init({
  dsn: 'https://3b26c2ffd0d51727f502bbbd7b3b2b3d@o4506981619269632.ingest.us.sentry.io/4507140692246528',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})


const queryClient = new QueryClient()
const container = document.getElementById('root')
// if (container) {
//   createRoot(container).render(
//     <QueryClientProvider client={queryClient}>
//       <MetronicI18nProvider>
//         <ThemeModeProvider>
//           <AuthProvider>
//             <AppRoutes />
//           </AuthProvider>
//         </ThemeModeProvider>
//       </MetronicI18nProvider>
//       <ReactQueryDevtools initialIsOpen={false} />
//     </QueryClientProvider>
//   )
// }
if (container) {
  render(
    // Use ReactDOM.render to render your app
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <ThemeModeProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeModeProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
    container
  )
}
