import ReactApexChart from 'react-apexcharts'

const ApexChart: React.FC<any> = ({statsArray}) => {
  const series = statsArray

  const options: any = {
    chart: {
      height: 390,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 20,
        endAngle: 270,

        hollow: {
          margin: 5,
          borderRadius: '20px',
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    colors: ['#02F1BA', '#FFA300', '#00C8BD', '#FF395B'],
    labels: ['Videos viewed', 'Not opened', 'Interested', 'Not interested'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',

      position: 'left',
      offsetX: 40,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName: any, opts: any) {
        return seriesName
        // return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 5,
      },
    },
    responsive: [
      {
        breakpoint: 0, // This corresponds to extra small devices
        options: {
          chart: {
            height: 290, // Set height to 300 for extra small devices
          },
          legend: {
            fontSize: '12px', // Adjust legend font size for small devices
            position: 'bottom', // Change legend position to bottom for better visibility
            offsetX: 0,
            offsetY: 15,
            itemMargin: {
              vertical: 3,
            },
          },
        },
      },
      {
        breakpoint: 272,
        options: {
          chart: {
            height: 170,
            type: 'radialBar',
          },
          legend: {
            fontSize: '6px',
            position: 'left',
            offsetX: -30,
            offsetY: -21,
            itemMargin: {
              vertical: 0,
            },
          },
        },
      },
      {
        breakpoint: 320,
        options: {
          chart: {
            height: 220,
            type: 'radialBar',
          },
          legend: {
            fontSize: '10px',
            position: 'left',
            offsetX: -30,
            offsetY: -11,
            itemMargin: {
              vertical: 1,
            },
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            height: 280,
            type: 'radialBar',
          },
          legend: {
            fontSize: '12px',
            position: 'left',
            offsetX: -20,
            offsetY: 15,
            itemMargin: {
              vertical: 3,
            },
          },
        },
      },
      {
        breakpoint: 680,
        options: {
          chart: {
            height: 290,
            type: 'radialBar',
          },
          legend: {
            fontSize: '12px',
            position: 'left',
            offsetX: 20,
            offsetY: 15,
            itemMargin: {
              vertical: 3,
            },
          },
        },
      },
      {
        breakpoint: 820,
        options: {
          chart: {
            height: 290,
            type: 'radialBar',
          },
          legend: {
            fontSize: '12px',
            position: 'left',
            offsetX: 140,
            offsetY: 15,
            itemMargin: {
              vertical: 3,
            },
          },
        },
      },
      {
        breakpoint: 991,
        options: {
          chart: {
            height: 380,
            type: 'radialBar',
          },
          legend: {
            fontSize: '16px',
            position: 'left',
            offsetX: 200,
            offsetY: 15,
            itemMargin: {
              vertical: 4,
            },
          },
        },
      },
    ],
  }

  return (
    <div id='chart'>
      <ReactApexChart options={options} series={series} type='radialBar' height={390} />
    </div>
  )
}

export default ApexChart
