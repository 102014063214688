/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
type Props = {
  unopened: any
}

const OpportunityUnopenedCell: FC<Props> = ({unopened}) => (
  <>
    <div className=''>{unopened?.opportunityStats?.videosNotOpened}</div>
  </>
)

export {OpportunityUnopenedCell}
