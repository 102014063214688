import React, {FC} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const Step5: FC<any> = ({url}) => {
  console.log('url', url)

  return (
    <div className='w-100'>
      <div className='pb-8 pb-xl-10 d-none d-xl-block'>
        <h2 className='fw-bold text-dark'>Recorded Video </h2>

        <div className='text-gray-400 fw-semibold fs-6'>Your recorded video</div>
      </div>

      <div className='mb-0'>
        {/* <iframe
          width='80%'
          height='315'
          src={url}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        ></iframe> */}
        {url && (
          <video controls={true} className='w-100 video-container mt-1'>
            <source src={`${url}#t=0.001`}></source>
            {/* <source src={localStorage.getItem('trimmedUrlWithWatermark') || ''}></source> */}
          </video>
        )}
      </div>
    </div>
  )
}

export {Step5}
