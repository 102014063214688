/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

export function HeaderWrapperRecordVideo() {
  const {classes, attributes} = useLayout()

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', xl: '300px'}"
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex justify-content-between align-items-center'
        )}
      >
        <div className='header-logo me-5 me-md-10 flex-grow-1 flex-xl-grow-0'>
          <Link to='/'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/greetrs_white.svg')}
              className='logo-default h-25px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/greetrs.svg')}
              className='logo-sticky h-25px'
            />
          </Link>
        </div>

        <div className='d-flex align-items-stretch flex-shrink-0'>
          <Link to='/auth/registration' className='text-white btn border-0 btn-pink'>
            Join greetrs{' '}
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/icons/pointinghand.svg')}
              className='logo-sticky h-25px'
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
