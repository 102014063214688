import React, { useEffect, useRef } from 'react'
import {formatedDate} from '../../../../_metronic/helpers/FormatDate'
import {Link, useNavigate} from 'react-router-dom'
import {handleVideoStatus, handleVideoStatusColor} from '../../../../_metronic/helpers/VideoStatus'

export const ActivityVideos: React.FC<any> = ({recentVideos}) => {
  const navigate = useNavigate()

  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    const handlePlayEvent = () => {
      if (videoRef.current) {
        const trackList = videoRef.current.textTracks
        for (let i = 0; i < trackList.length; i++) {
          if (trackList[i].kind === 'subtitles' && trackList[i].language === 'en') {
            trackList[i].mode = 'showing'
          }
        }
      }
    }

    const videoElement = videoRef.current
    if (videoElement) {
      videoElement.addEventListener('play', handlePlayEvent)
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlayEvent)
      }
    }
  }, [])

  return (
    <div className='container-fluid my-5 px-0'>
      <div className='row'>
        {recentVideos?.map((item: any, index: number) => {
          return (
            <div
              // to={`/videoMessages/view-video/${item.id}`}
              className='col-xl-3 col-md-4 col-xs-6 d-flex align-items-stretch p-3 cursor-pointer'
              key={index}
              onClick={() => {
                navigate(`/videoMessages/view-video/${item.id}`, {
                  state: {
                    pageTitle: `To: ${item?.contactDetails?.name}`,
                    hoverDescription: `Dashboard > Video Sent > ${item?.contactDetails?.name}`,
                    pageDescription: `Dashboard > ... > ${item?.contactDetails?.name}`,
                  },
                })
              }}
            >
              <div className='card shadow-sm video__card'>
                <video
                  ref={videoRef}
                  controls={true}
                  poster={item?.thumbnailUrl || ''}
                  className='card-img-top w-100 video-container p-5 pb-0'
                  crossOrigin='anonymous'
                >
                  <source src={`${item.videoUrl}#t=0.001`} type='video/mp4' />
                  {item?.captions == null ? (
                              ''
                            ) : (
                              <track
                                src={`${item?.captions?.caption_url}`}
                                kind='subtitles'
                                srcLang='en'
                                label='English'
                                default
                              />
                            )}
                </video>

                <div className='card-body p-5'>
                  <div className='mb-4'>
                    <h5 className='text-pink fw-bolder'>{item.videoTitle}</h5>
                  </div>
                  <div className='row my-2'>
                    <span className='col-4 text-blue fw-bold'>Date</span>
                    <span className='col-8 text-body-text'>{formatedDate(item.createdAt)}</span>
                  </div>
                  <div className='row my-2'>
                    <span className='col-4 text-blue fw-bold'>Recipient</span>
                    <span className='col-8 text-body-text'>
                      {item?.contactDetails?.name || 'N/A'}
                    </span>
                  </div>
                  <div className='row my-2'>
                    <span className='col-4 text-blue fw-bold'>Status</span>
                    <span className='col-8  d-flex'>
                      <div className={`${handleVideoStatusColor(item)}  py-1 px-2 rounded`}>
                        {handleVideoStatus(item)}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ActivityVideos
