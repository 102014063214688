import firebase from '../config/firebase'
const getPricingQuota = async (priceId: string) => {
  let pricingDocs = await firebase
    .firestore()
    .collection('pricings')
    .where('priceId', '==', priceId)
    .get()
  if (pricingDocs.size) {
    let pricingData = pricingDocs.docs[0].data()
    return pricingData.features
  }
  return null
}

export {getPricingQuota}
