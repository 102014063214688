/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'

type Props = {
  user: any
}

const ContactInfoCell: FC<Props> = ({user}) => {
  const navigate = useNavigate()
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      {/* <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.initials?.state}`,
              `text-${user.initials?.state}`
            )}
          >
            {user.initials?.label}
          </div>
        )}
      </a>
    </div> */}
      <div className='d-flex flex-column'>
        <div
          //  to={`/contact/singleContact/${user.id}`}
          onClick={() => {
            navigate(`/contact/singleContact/${user.id}`, {
              state: {
                pageTitle: user?.name,
                pageDescription: `Search > ${user?.name}`,
              },
            })
          }}
          className='text-gray-800 text-hover-primary cursor-pointer'
        >
          {user.name}
        </div>
        {/* <span>{user.email}</span> */}
      </div>
    </div>
  )
}

export {ContactInfoCell}
