import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import NotificationsSettings from './components/NotificationsSettings'
import {PageTitle} from '../../../../_metronic/layout/core'
import AllNotifications from './components/AllNotifications'

const Notifications = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path='settings'
        element={
          <>
            <NotificationsSettings />
          </>
        }
      />
      <Route
        path='all'
        element={
          <>
            <AllNotifications />
          </>
        }
      />

      <Route index element={<Navigate to='/notifications/settings' />} />
      <Route index element={<Navigate to='/notifications/all' />} />
      
    </Route>
  </Routes>
)

export default Notifications
