import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {initialUser, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createContact, updateContact} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Col, Row} from 'react-bootstrap'

type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'First name must contain only alphabets')
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  lastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Last name must contain only alphabets')
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  // companyName: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Company name is required'),
  telephone: Yup.string().matches(/^[0-9+][0-9]*$/, 'Enter a valid phone number'),
  // .required('Phone number is required'),
})

const ContactEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<User>({
    ...user,
    firstName: user.firstName || initialUser.firstName || '',
    lastName: user.lastName || initialUser.lastName || '',
    email: user.email || initialUser.email || '',
    companyName: user.companyName || initialUser.companyName || '',
    telephone: user.telephone || initialUser.telephone || '',
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateContact(values)
        } else {
          await createContact(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-5'>
            {/* begin::Label */}
            <Row>
              <Col>
                <label className='required fw-semibold fs-6 mb-2'>First name</label>
                <input
                  placeholder='First name'
                  {...formik.getFieldProps('firstName')}
                  type='text'
                  name='firstName'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                    {
                      'is-valid': formik.touched.firstName && !formik.errors.firstName,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
              </Col>
              <Col>
                <label className=' fw-semibold fs-6 mb-2'>Last name</label>
                <input
                  placeholder='Last name'
                  {...formik.getFieldProps('lastName')}
                  type='text'
                  name='lastName'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                    {
                      'is-valid': formik.touched.lastName && !formik.errors.lastName,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
              </Col>
            </Row>
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-5'>
            <label className=' fw-semibold fs-6 mb-2'>Company name </label>

            <input
              placeholder='Type to search for an existing company or add new'
              {...formik.getFieldProps('companyName')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0'
                // {'is-invalid': formik.touched.email && formik.errors.email},
                // {
                //   'is-valid': formik.touched.email && !formik.errors.email,
                // }
              )}
              type='text'
              name='companyName'
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />

            {/* {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )} */}
          </div>

          {/* begin::Input group */}
          <div className='fv-row mb-5'>
            <label className=' fw-semibold fs-6 mb-2'>Email</label>

            <input
              placeholder='Enter email or leave blank to copy the video URL'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />

            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert ' className='error-text'>
                  {formik.errors.email}
                </span>
              </div>
            )}
          </div>

          {/* begin::Input group */}
          <div className='fv-row mb-5'>
            {/* begin::Label */}
            <label className=' fw-semibold fs-6 mb-2'>Telephone</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Enter your phone number'
              {...formik.getFieldProps('telephone')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.telephone && formik.errors.telephone},
                {
                  'is-valid': formik.touched.telephone && !formik.errors.telephone,
                }
              )}
              // className='form-control form-control-solid mb-3 mb-lg-0'
              type='text'
              name='telephone'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.telephone && formik.errors.telephone && (
              <div className='fv-plugins-message-container'>
                <span role='alert error-text' className='error-text'>
                  {formik.errors.telephone}
                </span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-5'>
            {/* begin::Label */}
            <label className=' fw-semibold fs-6 mb-2'></label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            {/* <select
              className='form-select form-select-solid fw-bold'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value=''></option>
              <option value='Administrator'>Administrator</option>
              <option value='Analyst'>Analyst</option>
              <option value='Developer'>Developer</option>
              <option value='Support'>Support</option>
              <option value='Trial'>Trial</option>
            </select> */}
            {/* <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Administrator'
                  id='kt_modal_update_role_option_0'
                  checked={formik.values.role === 'Administrator'}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                  <div className='fw-bold text-gray-800'>Administrator</div>
                  <div className='text-gray-600'>
                    Best for business owners and company administrators
                  </div>
                </label>
              </div>
            </div> */}
            {/* end::Input row */}
            {/* <div className='separator separator-dashed my-5'></div> */}
            {/* begin::Input row */}
            {/* <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Developer'
                  id='kt_modal_update_role_option_1'
                  checked={formik.values.role === 'Developer'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                  <div className='fw-bold text-gray-800'>Developer</div>
                  <div className='text-gray-600'>
                    Best for developers or people primarily using the API
                  </div>
                </label>
              </div>
            </div> */}
            {/* end::Input row */}
            {/* <div className='separator separator-dashed my-5'></div> */}
            {/* begin::Input row */}
            {/* <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Analyst'
                  id='kt_modal_update_role_option_2'
                  checked={formik.values.role === 'Analyst'}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                  <div className='fw-bold text-gray-800'>Analyst</div>
                  <div className='text-gray-600'>
                    Best for people who need full access to analytics data, but don't need to update
                    business settings
                  </div>
                </label>
              </div>
            </div> */}
            {/* end::Input row */}
            {/* <div className='separator separator-dashed my-5'></div> */}
            {/* begin::Input row */}
            {/* <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Support'
                  id='kt_modal_update_role_option_3'
                  checked={formik.values.role === 'Support'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_3'>
                  <div className='fw-bold text-gray-800'>Support</div>
                  <div className='text-gray-600'>
                    Best for employees who regularly refund payments and respond to disputes
                  </div>
                </label>
              </div>
            </div> */}
            {/* end::Input row */}
            {/* <div className='separator separator-dashed my-5'></div> */}
            {/* begin::Input row */}
            {/* <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  id='kt_modal_update_role_option_4'
                  value='Trial'
                  checked={formik.values.role === 'Trial'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_4'>
                  <div className='fw-bold text-gray-800'>Trial</div>
                  <div className='text-gray-600'>
                    Best for people who need to preview content data, but don't need to make any
                    updates
                  </div>
                </label>
              </div>
            </div> */}
            {/* end::Input row */}
            {/* end::Roles */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{user.id ? 'Update' : 'Add Contact'}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {ContactEditModalForm}
