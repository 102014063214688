import {FC, useEffect, useState} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, RightToolbar, ActivityDrawer, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import {WithChildren} from '../helpers'
import {themeModeSwitchHelper, useThemeMode} from '../partials/layout/theme-mode/ThemeModeProvider'
import {getAuth, useAuth} from '../../app/modules/auth'
import {fetchBillingCards} from '../../app/modules/accounts/core/_requests'
import {Alert} from 'react-bootstrap'
import {checkUserStatus} from '../../app/modules/auth/core/_requests'

const MasterLayout: FC<WithChildren> = ({children}) => {
  const [isFetchBillingLoading, setIsFetchBillingLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])
  const [billingAddresses, setBillingAddresses] = useState<any[]>([])
  const {currentUser} = useAuth()
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  useEffect(() => {
    const userData = async () =>
      checkUserStatus(currentUser?.uid, (data) => {
        if (data.cardId) {
          setShowBillingError(false)
        } else {
          setShowBillingError(true)
        }
      })
    userData()
  }, [])

  useEffect(() => {
    setIsFetchBillingLoading(true)
    const fetchData = async () => {
      try {
        const auth = getAuth()
        const data = await fetchBillingCards(auth?.uid)
        setPaymentMethods(data.data)
        setIsFetchBillingLoading(false)
      } catch (error) {
        setIsFetchBillingLoading(false)
        console.error('Error fetching billing card data:', error)
      }
    }

    fetchData()
  }, [])

  console.log('paymentMethods', paymentMethods)

  const billingNotFound = () => {
    if (
      currentUser?.role == 'super-admin' &&
      !currentUser?.allowFreeAccess &&
      !isFetchBillingLoading &&
      paymentMethods?.length == 0
    ) {
      return true
    } else {
      return false
    }
  }
  const [showBilingError, setShowBillingError] = useState(false)
  const [showPaymentError, setShowPaymentError] = useState(false)

  useEffect(() => {
    if (billingNotFound()) {
      setShowBillingError(true)
    } else {
      setShowBillingError(false)
    }
    if (
      !currentUser?.allowFreeAccess &&
      currentUser?.billingError &&
      currentUser?.role == 'super-admin'
    ) {
      setShowPaymentError(true)
    } else {
      setShowPaymentError(false)
    }
  }, [isFetchBillingLoading, currentUser, currentUser?.billingError])

  return (
    <PageDataProvider>
      {/* {!currentUser?.allowFreeAccess &&
        currentUser?.billingError &&
        currentUser?.role == 'super-admin' && (
          <div className='bg-danger shadow fw-semibold d-flex justify-content-center align-items-center p-3 text-white gap-2'>
            <p className='mb-0'>
              Your last payment was failed please update your billing credential from{' '}
            </p>
            <Link className='text-white text-decoration-underline ' to={'/account/billing'}>
              here
            </Link>
          </div>
        )} */}
      {/* {billingNotFound() && (
        <div className='bg-danger shadow fw-semibold d-flex justify-content-center align-items-center p-3 text-white gap-2'>
          <p className='mb-0'>
            You dont have any billing method please attach your billing details from{' '}
          </p>
          <Link className='text-white text-decoration-underline ' to={'/account/billing'}>
            here
          </Link>
        </div>
      )} */}
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {!isFetchBillingLoading && showPaymentError && (
            <Alert
              variant='danger'
              className='custom-alert shadow'
              onClose={() => setShowPaymentError(false)}
              dismissible
            >
              <Alert.Heading className='text-danger'>Payment Failed:</Alert.Heading>
              <p className='mb-0 fw-semibold '>
                Your last payment was failed please update your billing credential from{' '}
              </p>
              <Link className='text-danger text-decoration-underline ' to={'/account/billing'}>
                here
              </Link>
            </Alert>
          )}
          {/* {!isFetchBillingLoading && showBilingError && (
            <Alert
              variant='danger'
              className='custom-alert shadow'
              onClose={() => setShowBillingError(false)}
              dismissible
            >
              <Alert.Heading className='text-danger'>Billing Error:</Alert.Heading>
              <p className='mb-0 fw-semibold '>
                You don't have any billing method please attach your billing details from
              </p>
              <Link
                className='text-danger text-decoration-underline '
                to={'/account/billing'}
              >
                here
              </Link>
            </Alert>
          )} */}
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AsideDefault />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
