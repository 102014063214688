/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  notInterested: any
}

const OpportunityNotInterestedCell: FC<Props> = ({notInterested}) => (
  <>
    <div className=''>{notInterested?.opportunityStats.notInterested}</div>
  </>
)

export {OpportunityNotInterestedCell}
