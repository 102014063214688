/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  received: any
}

const OpportunityReceivedCell: FC<Props> = ({received}) => (
  <>
    <div className=''>{received?.opportunityStats?.videosReceived}</div>
  </>
)

export {OpportunityReceivedCell}
