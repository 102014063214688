export const formatedDate = (createdAt: {seconds: number}) => {
  const date = new Date(createdAt.seconds * 1000)
  const options: any = {year: 'numeric', month: 'long', day: 'numeric'}
  const newDate = new Intl.DateTimeFormat('en-US', options).format(date)
  return newDate
}

export const longDateFormat = (createdAt: {seconds: number}) => {
  const date = new Date(createdAt.seconds * 1000)

  // Get day, month, and year components
  const day: number = date.getDate()
  const month: string = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(date)
  const year: number = date.getFullYear()

  // Construct the formatted date string
  const formattedDate: string = `${day} ${month} ${year}`

  return formattedDate
}

export const customDateFormat = (createdAt: {seconds: number}) => {
  const date = new Date(createdAt.seconds * 1000)

  // Get day and month components
  const day = date.getDate()
  const month = date.getMonth() + 1 // Months are zero-based, so add 1

  // Get year component
  const year = date.getFullYear()

  // Construct the formatted date string
  const formattedDate = `${day}.${month}.${year}`

  return formattedDate
}
