import axios from 'axios'
import firebase from '../../../../config/firebase'

import {RepositoryFactory} from '../../../../repository/RepositoryFactory'

let videoApi = RepositoryFactory.get('video')

let recordedVideoCollection = firebase.firestore().collection('recordedVideos')

// ===========Upload Video============
const uploadVideo: any = async (
  payload: any,
  onSuccess = (res: any) => {},
  onError = (err: any) => {}
) => {
  try {
    const {data} = await videoApi.uploadVideo(payload)
    console.log('In upload video res...', data)

    if (data.success) {
      onSuccess(data)
    } else {
      onError('Unable to upload video!')
      throw new Error(JSON.stringify({message: 'video upload failure', error: data}))
    }
  } catch (error: any) {
    // alert(error?.response?.data?.error || 'Something went wrong!')
    onError(error?.response?.data?.error)
    console.log('In upload video error...', error)
  }
}

const uploadVideoOnFirebase: any = async (
  payload: any,
  onSuccess = (res: any, id: any) => {},
  onError = (err: any) => {},
  onProgress = (progress: number) => {}
) => {
  try {
    const storageRef = firebase.storage().ref()
    const videoRef = storageRef.child(`uploadVideos/${payload.name}`)
    const uploadTask = videoRef.put(payload)

    // Monitor upload progress
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // Calculate upload progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        onProgress(progress)

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running')
            break
        }
      },
      (error) => {
        onError(error)
        console.log('In upload video error...', error)
      },
      async () => {
        const videoData = {
          filename: payload.name,
        }

        const query = await firebase.firestore().collection('uploadVideos').add(videoData)
        firebase
          .firestore()
          .collection('uploadVideos')
          .doc(query.id)
          .onSnapshot((doc) => {
            onSuccess(doc.data(), query.id)
          })
      }
    )
  } catch (error: any) {
    onError(error?.response?.data?.error)
    console.log('In upload video error...', error)
  }
}

// ===========Upload Video============
const uploadTrimVideo: any = async (
  url: string,
  allowCaptions: boolean,
  onSuccess = (res: any) => {},
  onError = (err: any) => {}
) => {
  try {
    const {data} = await videoApi.uploadTrimVideo(url, allowCaptions)
    console.log(allowCaptions, 'allowCaptionsbyahsan')
    console.log('In upload video res...', data)

    if (data.success) {
      onSuccess(data)
    } else {
      onError('Unable to upload video')
    }
  } catch (error: any) {
    // alert(error?.response?.data?.error || 'Something went wrong!')
    onError(error?.response?.data?.error)
    console.log('In upload video error...', error)
  }
}

const deleteUploadedVideo: any = async (doc: string, onSuccess = () => {}, onError = () => {}) => {
  try {
    firebase
      .firestore()
      .collection('uploadVideos')
      .doc(doc)
      .delete()
      .then(() => {
        onSuccess()
      })
  } catch (error: any) {
    onError()
  }
}

const checkOpportunityDetails: any = async (
  payload: any,
  onSuccess = (res: any) => {},
  onError = () => {}
) => {
  try {
    let details = await recordedVideoCollection
      // .where('sendById', '==', payload?.id)
      .where('opportunity', '==', payload?.opportunity)
      .orderBy('createdAt', 'desc')
      .get()
    if (details.size > 0) {
      onSuccess({...details.docs[0].data()})
    } else {
      onError()
    }
    console.log('Size check', details)
  } catch (error: any) {
    onError()
  }
}
export {
  uploadVideo,
  uploadTrimVideo,
  checkOpportunityDetails,
  uploadVideoOnFirebase,
  deleteUploadedVideo,
}
